import React from "react";

const CzechPrivatePolicy = `
 <>  
 <p className="text-xs sm:text-sm text-gray-500">
 Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2,zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264 (dále jen „P4L“), jakožto poskytovatel software, IT řešení a marketingu a provozovatel webového portálu www.p4life.cz a www.p4life-rk.cz,při správě a zpracování osobních údajů svých klientů a spolupracujících osob plně respektuje zásady jejich ochrany a v souladu s platnými právními předpisy níže informuje subjekty osobních údajů o jejich právech, důvodech zpracování a další podstatné informace týkající se zpracování osobních údajů.

 <br /><br />Společnost P4L, s.r.o. se v plném rozsahu řídí pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“) a v souladu s tímto Nařízením přísně dodržuje všechny nastavené postupy.

 <br /><br />Pokud se jakákoli část tohoto dokumentu bude zdát nesrozumitelná nebo vyplyne-li potřeba dalšího podrobnějšího vysvětlení některé problematiky související se zpracováním osobních údajů, je možné obrátit se na naši emailovou adresu:obchod@p4life.cz.
</p>

<p className="text-xs sm:text-sm text-gray-500">
 Obsah:

 <br /><br />1. Správce osobních údajů a jeho identifikace – kdo to je.
 <br />2. Rozsah zpracování osobních údajů.
 <br />3. Doba, po kterou jsou osobní údaje zpracovány.
 <br />4. Kdo má k osobním údajům přístup, komu jsou poskytovány.
 <br />5. Automatizované zpracování osobních údajů.
 <br />6. Jaká jsou práva subjektu údajů.
 <br />7. Uplatnění práv subjektů údajů – klientů společnosti P4L.8. Souhlas se zpracováním osobních údajů.

</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'>1. Správce osobních údajů a jeho identifikace – kdo to je </span><br /><br />

 Správcem je osoba, která disponuje s osobními údaji subjektu údajů a zároveň určuje účel a prostředky jejich zpracování. Společnost P4L je správcem osobních údajů, jelikož shromažďuje osobní údaje zájemců o porovnání nabídek hypoték na základě zadaných kritérií a dále pro tyto zájemce zajišťuje možnost zpracování hypoteční analýzy hypotečním specialistou s cílem rychlého a efektivního vyřízení žádosti o hypotéku.

 <br /><br />Kontaktní údaje a totožnost správce:

 <br /><br />Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, e-mail: obchod@p4life.cz.

 <br /><br />Pověřenec pro ochranu osobních údajů nebyl u Správce jmenován. Správce nemá zástupce pro plnění povinností ve smyslu Nařízení.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'>  2. Rozsah a kategorie zpracování osobních údajů.</span>

 <br /><br />Společnost P4L zpracovává pouze ty osobní údaje, které jsou nezbytné pro řádnéposkytování služeb, plnění zákonných povinností a též k ochraně zájmů společnosti P4L. Shromažďujeme osobní údaje zájemců o naše služby nebo potenciálních zájemců, kteří nám ke zpracování osobních údajů a k oslovení udělili souhlas.

 <br /><br />Kategorie osobních údajů, které společnost P4L zpracovává:

 <br />a) identifikační údaje – jméno, příjmení, datum narození, adresa bydliště, podpis,u podnikatelských subjektů IČ a adresa sídla.

 <br />b) kontaktní údaje – e-mailová adresa a telefonní číslo, adresa místa bydliště – jméno, příjmení, datum narození, adresa bydliště, podpis, u podnikatelských subjektů IČ a adresa sídla.

 <br />c) sociodemografické údaje (věk, příjmy, splátky úvěru, počet dětí) – tyto údaje společnost P4L zpracovává pouze v případě, kdy si subjekt údajů sám dobrovolně


 zvolí možnost rozšířených kritérií pro srovnání hypotéky na webovém portáluwww.p4life.cz a www.p4life-rk.cz, poskytnutí těchto údajů není povinné a záleží pouze na volbě subjektu údajů, zda má zájem o podrobnější výsledek srovnání hypotéky.

 <br /><br />Zpracování osobních údajů bez nutnosti udělení souhlasu:

 <br /><br />a) pro účely smluvního plnění

 <br />– poskytnutí osobních údajů pro účely srovnání a kalkulaci hypotéky na základě zadaných kritérií zájemce je smluvním požadavkem společnosti P4L.

 <br /><br />b) zpracování na základě plnění zákonných povinností

 <br />– společnost P4L zpracovává také osobní údaje, pokud jí tuto povinnost přikazuje zákon.

 <br /><br />c) zpracování na základě oprávněných zájmů společnosti P4L
 <br />– zejména pro účely obhajoby právních nároků společnosti P4L

 Zpracování osobních údajů s udělením souhlasu zájemce <br />– za účelem nabídky finančního produktu – hypotéky:
 <br />– udělení souhlasu zájemcem je dobrovolné, zároveň však nezbytné, aby jej mohl dále kontaktovat hypoteční specialista (Poradce) za účelem zpracování individuální nabídky, hypoteční analýzy a informování zájemce o finančníchproduktech – hypotékách dle kritérií zadaných zájemcem do srovnávače/kalkulačky hypoték na webovém portálu www.p4life.cz a www.p4life- rk.cz.

 <br />– na základě uděleného souhlasu pro účely nabídky finančního produktu –hypotéky může být zájemce kontaktován formou e-mailové zprávy nebo telefonicky, další komunikace probíhá dle preferencí zájemce.

 <br />– osobní údaje mohou být za účelem zpracování nabídky hypotéky poskytnuty hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se zájemcem finanční služby,



 <br />– bez uděleného souhlasu nemůže být zájemce informován ani kontaktován s nabídkou individuálního hypotečního produktu, nelze s ním o možnostech sjednání hypotéky či jiného finančního produktu jednat, nabídka finančních produktů nemůže být více zpřesňována,

 <br />– zájemce může udělený souhlas kdykoli odvolat a to písemně na adresuobchod@p4life.cz, písemně dopisem na korespondenční adresu společnosti P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'> 3.Doba, po kterou jsou osobní údaje zpracovány.</span>
 <br /><br />Osobní údaje klientů společnost P4L zpracovává po dobu, po kterou svým klientům poskytuje své služby nebo po dobu trvání smlouvy. Po ukončení smluvního vztahu a po vypořádání veškerých závazků z takové smlouvy plynoucích a jinak s ní souvisejících uchovává společnost P4L osobní údaje klientů po dobu nezbytně nutnou, která je vyžadována dle příslušných právních předpisů, např. dle zákona o účetnictví, zákona o archivnictví a evidenci, zákona o dani z přidané hodnoty, atd. a dle obecných i speciálních promlčecích lhůt.U udělených souhlasů se zpracováním pro účely nabídky finančního produktu je doba uchování osobních údajů 3 roky od udělení souhlasu.Společnost P4L se při uchování osobních údajů řídí zásadou minimalizace údajů, v okamžiku, kdy odpadne účel zpracování osobních údajů nebo uplyne doba, po kterou je společnost P4L povinna osobní údaje uchovávat, osobní údaje jsou zcela vymazány ze systémů, případně vhodným způsobem anonymizovány. Osobní údaje tak nikdy nejsou zpracovávány nebo uchovávány déle, než je nezbytně nutné.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'> 4.Kdo má k osobním údajům přístup, komu jsou poskytovány.</span>
 <br /><br />Osobní údaje klientů jsou zpřístupněny společnosti P4L jakožto jejich správci, dálepověřeným zaměstnancům společnosti P4L a smluvním zpracovatelům, se kterými má společnost P4L uzavřenou Smlouvu o zpracování osobních údajů, na základě které jsou tito smluvní zpracovatelé povinni osobní údaje zpracovávat v souladu s Nařízením. Smluvními zpracovateli jsou zejména hypoteční specialisté, tj. Poradci, poskytovatelé IT služeb, účetní a daňoví poradci.



 <br />Osobní údaje zpracovávané na základě uděleného souhlasu za účelem nabídky finančního produktu mohou být v souladu s uděleným souhlasemposkytnuty/předány jinému správci, a to konkrétnímu hypotečnímu specialistovi (dále jen „Poradce“), který má se společností P4L uzavřenou Smlouvu o spolupráci a dle zákona č. 257/2016 Sb., zákon o spotřebitelském úvěru, je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

 <br />Společnost P4L svým klientům garantuje, že osobní údaje, které zpracovává na základě uděleného souhlasu za účelem nabídky finančního produktu, nejsou v žádném případě předávány žádným externím call centrům ani marketingovýmspolečnostem zajišťujícím nejrůznější marketingové kampaně, vždy se jedná o konkrétní osobu hypotečního specialisty, která je smluvním partnerem společnosti P4L.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'> 5.Automatizované zpracování osobních údajů.</span>
 <br /><br />Součástí poskytování služeb společnosti P4L je i automatizované zpracování osobních údajů. Při automatizovaném zpracování osobních údajů jsou používány automatické informační systémy, např. elektronické kalkulačky, software, IT aplikace. Cílem automatizovaného zpracování osobních údajů je rychléa individuální poskytnutí služby dle specifických požadavků a potřeb klientů.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'> 6.Jaká jsou práva subjektu údajů.</span>
 <br /><br />a) Právo na informace týkající se zpracování osobních údajůKaždý subjekt zpracování osobních údajů má právo znát totožnost a kontaktní údaje na správce osobních údajů, jeho zástupce i na případné pověřence pro ochranu osobních údajů, dále má právo znát účel a právní základ zpracování osobních údajů, právo znát příjemce nebo kategorie příjemců osobních údajů, informace o předání osobních údajů do třetích zemí, dobu uložení osobních údajů, informaci o právu na odvolání souhlasu a o dalších právech subjektu údajů, možnost podat stížnost u Úřadu na ochranu osobních údajů, informaci, zda je poskytování osobních údajů zákonný či smluvní požadavek, informování o automatizovaném rozhodování a profilování.



 <br /><br />b) Právo na přístup k osobním údajům

 <br />Právo klienta požadovat od společnosti P4L, jakožto správce, potvrzení, zda jeho osobní údaje jsou či nejsou zpracovávány. Pokud zpracovávány jsou, tak máklient, jakožto subjekt údajů, právo získat přístup k těmto osobním údajům a k informacím o účelu jejich zpracování, kategoriích dotčených osobních údajů, příjemcích nebo kategoriích příjemců, kterým jsou, byly nebo mají být osobní údaje zpřístupněny, dobu zpracování osobních údajů, existence práva požadovat opravu, výmaz, omezení a možnosti vznést námitku proti zpracování, informace o právu podat stížnost u Úřadu pro ochranu osobních údajů, informace o zdroji osobních údajů, informace, zda dochází k automatizovanému rozhodování a profilování osobních údajů, informace o zárukách při předávání osobních údajů do třetích zemí nebo mezinárodním organizacím.

 <br />Subjekt údajů má právo požadovat od společnosti P4L kopie zpracovávaných osobních údajů. Při opakovaných a zjevně nepřiměřených žádostech je společnostP4L oprávněna účtovat klientům přiměřený poplatek na úhradu administrativních nákladů za pořízení kopie.

 <br />Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na adresu sídla nebo na e-mailovou adresu společnosti P4L.

 <br /><br />c) Právo na opravu osobních údajů

 <br />Subjekt údajů má právo žádat, aby společnost P4L bez zbytečného odkladu opravila nepřesné a doplnila neúplné osobní údaje, které se jej týkají. Základem uplatnění tohoto práva je sdělení skutečnosti vedoucí k opravě či doplnění společnosti P4L.

 <br /><br />d) Právo na výmaz osobních údajů

 <br />V případech, kdy pominul účel zpracování osobních údajů, byl odvolán souhlas ke zpracování osobních údajů a v dalších případech, které jsou podrobně uvedeny v čl. 17 odst. 1 písm. a) až f) Nařízení, je společnost P4L na základě Vaší žádosti povinna osobní údaje žádajícího klienta smazat. Pokud však není splněna zákonná podmínka pro výmaz, má společnost P4L právo si osobní údajeponechat. Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na korespondenční adresu společnosti P4L – Komenského 446/6,Jablonec nad Nisou, 466 01 nebo na e-mailovou adresu společnosti P4L.



 <br /><br />e) Právo na omezení zpracování osobních údajů

 <br />Na žádost klienta je společnost P4L povinna omezit zpracování jeho osobních údajů jen na nejnutnější zákonné důvody, pokud jsou splněny podmínky dle čl. 18 Nařízení. V obecné rovině lze toto právo uplatnit v případech, kdy není jasné, zda a kdy se budou muset osobní údaje odstranit.

 <br /><br />f) Právo vznesení námitky proti zpracování osobních údajů

 <br />V případech, kdy klient dojde ke zjištění, že společnost P4L zpracovává jeho osobní údaje v rozporu se zákonem nebo v rozporu s ochranou soukromého a osobního života klienta, má právo obrátit se přímo na společnost P4L s toutonámitkou, aby došlo k co nejrychlejšímu odstranění závadného stavu nebo k dostatečnému zdůvodnění nastalé situace. Námitky lze vznášet i proti automatizovanému rozhodování/profilování.

 <br /><br />g) Právo na přenositelnost údajů

 <br />Klient může požadovat, aby jeho osobní údaje, které společnosti P4L udělil, byly předány jinému správci dle požadavku klienta. Osobní údaje budou přeneseny v běžně používaném odpovídajícím formátu, nebude-li požadavku klienta bránit zákonná či jiná překážka.

 <br /><br />h) Právo podat podnět/stížnost na Úřad pro ochranu osobních údajů

 <br />S jakýmkoli podnětem či stížností týkající se zpracování osobních údajů se klient může obrátit přímo na Úřad pro ochranu osobních údajů, jakožto na dozorový orgán.
</p>
<p className="text-xs sm:text-sm text-gray-500">
<br /> <span className='text-base font-semibold text-gray-700'> 7. Uplatnění práv subjektů údajů – klientů společnosti P4L.</span>

 <br /><br />Jednotlivá práva uvedená v čl. 6 tohoto informačního dokumentu mohou subjekty údajů uplatnit u správce, společnosti P4L buď e-mailovou zprávou na adresu:obchod@p4life.cz nebo písemně dopisem na adresu společnosti P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

 <br /><br />Žádosti subjektů údajů je společnost P4L povinna vyřešit nejpozději ve lhůtě 1 měsíce ode dne přijetí žádosti. V odůvodněných případech zejména s ohledem na rozsah žádosti je společnost P4L oprávněna lhůtu



 k vyjádření prodloužit na 2 měsíce, o nutnosti prodloužení společnost P4L subjektúdajů včas informuje.

 <br /><br />Dokument je pravidelně aktualizován, datum poslední aktualizace 25. 5. 2018.

 <br /><br />Souhlas se zpracováním osobních údajů

 <br />Tímto uděluji společnosti P4L s. r. o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, zastoupena jednatelem Lukášem Berkou, email: obchod@p4life.cz (dále jen „Správce“) souhlas se zpracováním osobních údajův rozsahu:

 <br />- jméno a příjmení
 <br />- emailová adresa
 <br />- telefonní číslo
 <br />- adresa trvalého bydliště
 <br />- věk žadatele
 <br />- měsíční příjem
 <br />- měsíční splátky úvěru
 <br />- členové domácnosti
 <br />- počet žadatelů
 <br />- platební historie

 <br /><br />(dále jen „Osobní údaje“)

 <br />za účelem:

 <br /><br />Osobní údaje budou použity za účelem zasílání informací, produktů, služeb, novinek a aktualit týkajících se finančního trhu tj. newsletterů a dále ke zpracování nabídky finančních produktů dle kritérií zadaných subjektemúdajů do srovnávače / kalkulačky hypoték na webovém portálu Správcehttp://www.p4life.cz a www.p4life-rk.cz, ke kontaktování subjektu údajůe-mailem či telefonicky a k dalšímu jednání o finančních produktech s hypotečním specialistou.

 <br /><br />Osobní údaje jsou zpracovávány 3 roky a může na základě jejich zpracování docházet k automatizovanému zpracování či profilování.



 <br /><br />Osobní údaje mohou být za účelem zpracování nabídky finančního produktu poskytnuty / předány jinému správci, a to hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

 <br /><br />Dále beru na vědomí, že tentosouhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresuSprávce nebo na e-mailovou adresu obchod@p4life.cz, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu.

 <br /><br />Více informací o zpracování mých osobních údajů je uvedeno v informačním dokumentu, který je k dispozici na webové adrese www.p4life.cz a www.p4life- rk.cz.

 <br /><br />V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy obchod@p4life.cz.
</p>
</>;
`;

const EnglishPrivatePolicy = `
<>
<p className="text-xs sm:text-sm text-gray-500">
        Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2,zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264 (dále jen „P4L“), jakožto poskytovatel software, IT řešení a marketingu a provozovatel webového portálu www.p4life.cz a www.p4life-rk.cz,při správě a zpracování osobních údajů svých klientů a spolupracujících osob plně respektuje zásady jejich ochrany a v souladu s platnými právními předpisy níže informuje subjekty osobních údajů o jejich právech, důvodech zpracování a další podstatné informace týkající se zpracování osobních údajů.

        <br /><br />Společnost P4L, s.r.o. se v plném rozsahu řídí pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“) a v souladu s tímto Nařízením přísně dodržuje všechny nastavené postupy.

        <br /><br />Pokud se jakákoli část tohoto dokumentu bude zdát nesrozumitelná nebo vyplyne-li potřeba dalšího podrobnějšího vysvětlení některé problematiky související se zpracováním osobních údajů, je možné obrátit se na naši emailovou adresu:obchod@p4life.cz.
    </p>

    <p className="text-xs sm:text-sm text-gray-500">
        Obsah:

        <br /><br />1. Správce osobních údajů a jeho identifikace – kdo to je.
        <br />2. Rozsah zpracování osobních údajů.
        <br />3. Doba, po kterou jsou osobní údaje zpracovány.
        <br />4. Kdo má k osobním údajům přístup, komu jsou poskytovány.
        <br />5. Automatizované zpracování osobních údajů.
        <br />6. Jaká jsou práva subjektu údajů.
        <br />7. Uplatnění práv subjektů údajů – klientů společnosti P4L.8. Souhlas se zpracováním osobních údajů.

    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'>1. Správce osobních údajů a jeho identifikace – kdo to je </span><br /><br />

        Správcem je osoba, která disponuje s osobními údaji subjektu údajů a zároveň určuje účel a prostředky jejich zpracování. Společnost P4L je správcem osobních údajů, jelikož shromažďuje osobní údaje zájemců o porovnání nabídek hypoték na základě zadaných kritérií a dále pro tyto zájemce zajišťuje možnost zpracování hypoteční analýzy hypotečním specialistou s cílem rychlého a efektivního vyřízení žádosti o hypotéku.

        <br /><br />Kontaktní údaje a totožnost správce:

        <br /><br />Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, e-mail: obchod@p4life.cz.

        <br /><br />Pověřenec pro ochranu osobních údajů nebyl u Správce jmenován. Správce nemá zástupce pro plnění povinností ve smyslu Nařízení.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'>  2. Rozsah a kategorie zpracování osobních údajů.</span>

        <br /><br />Společnost P4L zpracovává pouze ty osobní údaje, které jsou nezbytné pro řádnéposkytování služeb, plnění zákonných povinností a též k ochraně zájmů společnosti P4L. Shromažďujeme osobní údaje zájemců o naše služby nebo potenciálních zájemců, kteří nám ke zpracování osobních údajů a k oslovení udělili souhlas.

        <br /><br />Kategorie osobních údajů, které společnost P4L zpracovává:

        <br />a) identifikační údaje – jméno, příjmení, datum narození, adresa bydliště, podpis,u podnikatelských subjektů IČ a adresa sídla.

        <br />b) kontaktní údaje – e-mailová adresa a telefonní číslo, adresa místa bydliště – jméno, příjmení, datum narození, adresa bydliště, podpis, u podnikatelských subjektů IČ a adresa sídla.

        <br />c) sociodemografické údaje (věk, příjmy, splátky úvěru, počet dětí) – tyto údaje společnost P4L zpracovává pouze v případě, kdy si subjekt údajů sám dobrovolně


        zvolí možnost rozšířených kritérií pro srovnání hypotéky na webovém portáluwww.p4life.cz a www.p4life-rk.cz, poskytnutí těchto údajů není povinné a záleží pouze na volbě subjektu údajů, zda má zájem o podrobnější výsledek srovnání hypotéky.

        <br /><br />Zpracování osobních údajů bez nutnosti udělení souhlasu:

        <br /><br />a) pro účely smluvního plnění

        <br />– poskytnutí osobních údajů pro účely srovnání a kalkulaci hypotéky na základě zadaných kritérií zájemce je smluvním požadavkem společnosti P4L.

        <br /><br />b) zpracování na základě plnění zákonných povinností

        <br />– společnost P4L zpracovává také osobní údaje, pokud jí tuto povinnost přikazuje zákon.

        <br /><br />c) zpracování na základě oprávněných zájmů společnosti P4L
        <br />– zejména pro účely obhajoby právních nároků společnosti P4L

        Zpracování osobních údajů s udělením souhlasu zájemce <br />– za účelem nabídky finančního produktu – hypotéky:
        <br />– udělení souhlasu zájemcem je dobrovolné, zároveň však nezbytné, aby jej mohl dále kontaktovat hypoteční specialista (Poradce) za účelem zpracování individuální nabídky, hypoteční analýzy a informování zájemce o finančníchproduktech – hypotékách dle kritérií zadaných zájemcem do srovnávače/kalkulačky hypoték na webovém portálu www.p4life.cz a www.p4life- rk.cz.

        <br />– na základě uděleného souhlasu pro účely nabídky finančního produktu –hypotéky může být zájemce kontaktován formou e-mailové zprávy nebo telefonicky, další komunikace probíhá dle preferencí zájemce.

        <br />– osobní údaje mohou být za účelem zpracování nabídky hypotéky poskytnuty hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se zájemcem finanční služby,



        <br />– bez uděleného souhlasu nemůže být zájemce informován ani kontaktován s nabídkou individuálního hypotečního produktu, nelze s ním o možnostech sjednání hypotéky či jiného finančního produktu jednat, nabídka finančních produktů nemůže být více zpřesňována,

        <br />– zájemce může udělený souhlas kdykoli odvolat a to písemně na adresuobchod@p4life.cz, písemně dopisem na korespondenční adresu společnosti P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'> 3.Doba, po kterou jsou osobní údaje zpracovány.</span>
        <br /><br />Osobní údaje klientů společnost P4L zpracovává po dobu, po kterou svým klientům poskytuje své služby nebo po dobu trvání smlouvy. Po ukončení smluvního vztahu a po vypořádání veškerých závazků z takové smlouvy plynoucích a jinak s ní souvisejících uchovává společnost P4L osobní údaje klientů po dobu nezbytně nutnou, která je vyžadována dle příslušných právních předpisů, např. dle zákona o účetnictví, zákona o archivnictví a evidenci, zákona o dani z přidané hodnoty, atd. a dle obecných i speciálních promlčecích lhůt.U udělených souhlasů se zpracováním pro účely nabídky finančního produktu je doba uchování osobních údajů 3 roky od udělení souhlasu.Společnost P4L se při uchování osobních údajů řídí zásadou minimalizace údajů, v okamžiku, kdy odpadne účel zpracování osobních údajů nebo uplyne doba, po kterou je společnost P4L povinna osobní údaje uchovávat, osobní údaje jsou zcela vymazány ze systémů, případně vhodným způsobem anonymizovány. Osobní údaje tak nikdy nejsou zpracovávány nebo uchovávány déle, než je nezbytně nutné.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'> 4.Kdo má k osobním údajům přístup, komu jsou poskytovány.</span>
        <br /><br />Osobní údaje klientů jsou zpřístupněny společnosti P4L jakožto jejich správci, dálepověřeným zaměstnancům společnosti P4L a smluvním zpracovatelům, se kterými má společnost P4L uzavřenou Smlouvu o zpracování osobních údajů, na základě které jsou tito smluvní zpracovatelé povinni osobní údaje zpracovávat v souladu s Nařízením. Smluvními zpracovateli jsou zejména hypoteční specialisté, tj. Poradci, poskytovatelé IT služeb, účetní a daňoví poradci.



        <br />Osobní údaje zpracovávané na základě uděleného souhlasu za účelem nabídky finančního produktu mohou být v souladu s uděleným souhlasemposkytnuty/předány jinému správci, a to konkrétnímu hypotečnímu specialistovi (dále jen „Poradce“), který má se společností P4L uzavřenou Smlouvu o spolupráci a dle zákona č. 257/2016 Sb., zákon o spotřebitelském úvěru, je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

        <br />Společnost P4L svým klientům garantuje, že osobní údaje, které zpracovává na základě uděleného souhlasu za účelem nabídky finančního produktu, nejsou v žádném případě předávány žádným externím call centrům ani marketingovýmspolečnostem zajišťujícím nejrůznější marketingové kampaně, vždy se jedná o konkrétní osobu hypotečního specialisty, která je smluvním partnerem společnosti P4L.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'> 5.Automatizované zpracování osobních údajů.</span>
        <br /><br />Součástí poskytování služeb společnosti P4L je i automatizované zpracování osobních údajů. Při automatizovaném zpracování osobních údajů jsou používány automatické informační systémy, např. elektronické kalkulačky, software, IT aplikace. Cílem automatizovaného zpracování osobních údajů je rychléa individuální poskytnutí služby dle specifických požadavků a potřeb klientů.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'> 6.Jaká jsou práva subjektu údajů.</span>
        <br /><br />a) Právo na informace týkající se zpracování osobních údajůKaždý subjekt zpracování osobních údajů má právo znát totožnost a kontaktní údaje na správce osobních údajů, jeho zástupce i na případné pověřence pro ochranu osobních údajů, dále má právo znát účel a právní základ zpracování osobních údajů, právo znát příjemce nebo kategorie příjemců osobních údajů, informace o předání osobních údajů do třetích zemí, dobu uložení osobních údajů, informaci o právu na odvolání souhlasu a o dalších právech subjektu údajů, možnost podat stížnost u Úřadu na ochranu osobních údajů, informaci, zda je poskytování osobních údajů zákonný či smluvní požadavek, informování o automatizovaném rozhodování a profilování.



        <br /><br />b) Právo na přístup k osobním údajům

        <br />Právo klienta požadovat od společnosti P4L, jakožto správce, potvrzení, zda jeho osobní údaje jsou či nejsou zpracovávány. Pokud zpracovávány jsou, tak máklient, jakožto subjekt údajů, právo získat přístup k těmto osobním údajům a k informacím o účelu jejich zpracování, kategoriích dotčených osobních údajů, příjemcích nebo kategoriích příjemců, kterým jsou, byly nebo mají být osobní údaje zpřístupněny, dobu zpracování osobních údajů, existence práva požadovat opravu, výmaz, omezení a možnosti vznést námitku proti zpracování, informace o právu podat stížnost u Úřadu pro ochranu osobních údajů, informace o zdroji osobních údajů, informace, zda dochází k automatizovanému rozhodování a profilování osobních údajů, informace o zárukách při předávání osobních údajů do třetích zemí nebo mezinárodním organizacím.

        <br />Subjekt údajů má právo požadovat od společnosti P4L kopie zpracovávaných osobních údajů. Při opakovaných a zjevně nepřiměřených žádostech je společnostP4L oprávněna účtovat klientům přiměřený poplatek na úhradu administrativních nákladů za pořízení kopie.

        <br />Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na adresu sídla nebo na e-mailovou adresu společnosti P4L.

        <br /><br />c) Právo na opravu osobních údajů

        <br />Subjekt údajů má právo žádat, aby společnost P4L bez zbytečného odkladu opravila nepřesné a doplnila neúplné osobní údaje, které se jej týkají. Základem uplatnění tohoto práva je sdělení skutečnosti vedoucí k opravě či doplnění společnosti P4L.

        <br /><br />d) Právo na výmaz osobních údajů

        <br />V případech, kdy pominul účel zpracování osobních údajů, byl odvolán souhlas ke zpracování osobních údajů a v dalších případech, které jsou podrobně uvedeny v čl. 17 odst. 1 písm. a) až f) Nařízení, je společnost P4L na základě Vaší žádosti povinna osobní údaje žádajícího klienta smazat. Pokud však není splněna zákonná podmínka pro výmaz, má společnost P4L právo si osobní údajeponechat. Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na korespondenční adresu společnosti P4L – Komenského 446/6,Jablonec nad Nisou, 466 01 nebo na e-mailovou adresu společnosti P4L.



        <br /><br />e) Právo na omezení zpracování osobních údajů

        <br />Na žádost klienta je společnost P4L povinna omezit zpracování jeho osobních údajů jen na nejnutnější zákonné důvody, pokud jsou splněny podmínky dle čl. 18 Nařízení. V obecné rovině lze toto právo uplatnit v případech, kdy není jasné, zda a kdy se budou muset osobní údaje odstranit.

        <br /><br />f) Právo vznesení námitky proti zpracování osobních údajů

        <br />V případech, kdy klient dojde ke zjištění, že společnost P4L zpracovává jeho osobní údaje v rozporu se zákonem nebo v rozporu s ochranou soukromého a osobního života klienta, má právo obrátit se přímo na společnost P4L s toutonámitkou, aby došlo k co nejrychlejšímu odstranění závadného stavu nebo k dostatečnému zdůvodnění nastalé situace. Námitky lze vznášet i proti automatizovanému rozhodování/profilování.

        <br /><br />g) Právo na přenositelnost údajů

        <br />Klient může požadovat, aby jeho osobní údaje, které společnosti P4L udělil, byly předány jinému správci dle požadavku klienta. Osobní údaje budou přeneseny v běžně používaném odpovídajícím formátu, nebude-li požadavku klienta bránit zákonná či jiná překážka.

        <br /><br />h) Právo podat podnět/stížnost na Úřad pro ochranu osobních údajů

        <br />S jakýmkoli podnětem či stížností týkající se zpracování osobních údajů se klient může obrátit přímo na Úřad pro ochranu osobních údajů, jakožto na dozorový orgán.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
    <br /> <span className='text-base font-semibold text-gray-700'> 7. Uplatnění práv subjektů údajů – klientů společnosti P4L.</span>

        <br /><br />Jednotlivá práva uvedená v čl. 6 tohoto informačního dokumentu mohou subjekty údajů uplatnit u správce, společnosti P4L buď e-mailovou zprávou na adresu:obchod@p4life.cz nebo písemně dopisem na adresu společnosti P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

        <br /><br />Žádosti subjektů údajů je společnost P4L povinna vyřešit nejpozději ve lhůtě 1 měsíce ode dne přijetí žádosti. V odůvodněných případech zejména s ohledem na rozsah žádosti je společnost P4L oprávněna lhůtu



        k vyjádření prodloužit na 2 měsíce, o nutnosti prodloužení společnost P4L subjektúdajů včas informuje.

        <br /><br />Dokument je pravidelně aktualizován, datum poslední aktualizace 25. 5. 2018.

        <br /><br />Souhlas se zpracováním osobních údajů

        <br />Tímto uděluji společnosti P4L s. r. o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, zastoupena jednatelem Lukášem Berkou, email: obchod@p4life.cz (dále jen „Správce“) souhlas se zpracováním osobních údajův rozsahu:

        <br />- jméno a příjmení
        <br />- emailová adresa
        <br />- telefonní číslo
        <br />- adresa trvalého bydliště
        <br />- věk žadatele
        <br />- měsíční příjem
        <br />- měsíční splátky úvěru
        <br />- členové domácnosti
        <br />- počet žadatelů
        <br />- platební historie

        <br /><br />(dále jen „Osobní údaje“)

        <br />za účelem:

        <br /><br />Osobní údaje budou použity za účelem zasílání informací, produktů, služeb, novinek a aktualit týkajících se finančního trhu tj. newsletterů a dále ke zpracování nabídky finančních produktů dle kritérií zadaných subjektemúdajů do srovnávače / kalkulačky hypoték na webovém portálu Správcehttp://www.p4life.cz a www.p4life-rk.cz, ke kontaktování subjektu údajůe-mailem či telefonicky a k dalšímu jednání o finančních produktech s hypotečním specialistou.

        <br /><br />Osobní údaje jsou zpracovávány 3 roky a může na základě jejich zpracování docházet k automatizovanému zpracování či profilování.



        <br /><br />Osobní údaje mohou být za účelem zpracování nabídky finančního produktu poskytnuty / předány jinému správci, a to hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

        <br /><br />Dále beru na vědomí, že tentosouhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresuSprávce nebo na e-mailovou adresu obchod@p4life.cz, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu.

        <br /><br />Více informací o zpracování mých osobních údajů je uvedeno v informačním dokumentu, který je k dispozici na webové adrese www.p4life.cz a www.p4life- rk.cz.

        <br /><br />V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy obchod@p4life.cz.
    </p>
</>;
`;
const translations = {
  "en": {
    "translation": {
      "common.chiefTechnologyOfficer": "chief technology officer",
      "common.chiefFinancilOfficer": "chief sales officer",
      "common.chiefSalesOfficer": "chief sales officer",
      "common.CTO": "CTO",
      "common.home": "Home",
      "common.conditions": "Conditions",
      "landing.header.blog": "Blog",
      "landing.header.leadership": "Leadership",
      "landing.header.results": "Values",
      "landing.header.group": "Group",
      "landing.header.P4LFinance": "P4L Finance",
      "landing.header.P4LRealEstate": "Bydlíme s klidem",
      "landing.header.LifeInSpain": "Life in Spain",
      "landing.header.P4LInvestment": "P4L Development",
      "landing.header.cleverSolutionsTechnology": "Clever Solutions Technology",
      "landing.header.P4LFinanceDescription": "Finance is second only to health.",
      "landing.header.LifeInSpainDescription": "Find your dream home abroad and experience the charm of Spanish coastal living in Costa del Sol.",
      "landing.header.P4LRealEstateDescription": "We do not sell real estate, but the homes of our clients, which can become your home.",
      "landing.header.P4LInvestmentDescription": "Let the money work for you.",
      "landing.header.cleverSolutionsTechnologyDescription": "Quality software should be a priority for any business.",
      "landing.header.contact": "Contact",
      "landing.hero.title1": "WE PROVIDE PROFESSIONAL SERVICES WITH HIGH ADDED VALUE",
      "landing.hero.title2": "FOR CLIENTS AND THEIR BUSINESS",
      "landing.hero.description": "We create our own financial ecosystem in which our clients live and breathe better.",
      "landing.hero.learnMore": "Learn more",
      "landing.hero.groupMembers": "Members of the Group",
      "landing.groupsSection.P4LFinanceFullDescription": "Finance is said to be second only to health. So it is not good to neglect or underestimate this area, and not to ignore it at all. A properly set up financial portfolio will save you a lot of time, worries and money. It will bring more peace and a clear head to your life in case of sudden situations.",
      "landing.groupsSection.LifeInSpainFullDescription": "Step into the vibrant world of Spain! Nestled along the stunning shores of the Costa del Sol, our real estate venture offers a gateway to sun-soaked paradise. From luxurious beachfront villas to charming Mediterranean apartments, immerse yourself in the allure of Spanish living. With a focus on foreign properties, we're here to turn your dream of owning a slice of Spanish paradise into a reality.",
      "landing.groupsSection.P4LRealEstateFullDescription": "We do not sell real estate, but the homes of our clients, which can become your home. Home is a place to which people look forward to, whether they are at work or anywhere else in the world. So when we sell, we proceed as if it were our own home. Our clients appreciate the human approach and the professionally managed whole process, which for us does not end with the release of the purchase price to the seller.",
      "landing.groupsSection.P4LInvestmentFullDescription": "Investing in real estate is a very popular form of valorizing earned money in the world with the philosophy: \"I worked to earn it, now it will work for me.\" Let your money work for you and see that investing can also be great fun.",
      "landing.groupsSection.cleverSolutionsTechnologyFullDescription": "We specialize in crafting cutting-edge software solutions tailored to the unique needs of our clients. Leveraging the expertise of our external partners and internal partners within P4L-GROUP, we ensure that every project is executed with precision and professionalism. Let yourself be surprised by our precision and creativity.",
      "landing.groupsSection.visitPage": "Visit website",
      "landing.groupsSection.interestedInInvestments": "I am interested in cooperation",
      "landing.groupsSection.BerkovaQuote": "The quality of thinking reflects the quality of life.",
      "landing.groupsSection.BerkaQuote": "In life you either have excuses or results. You can't have both.",
      "landing.groupsSection.HanusQuote": "When someone tells you something is impossible, show them how it's done.",
      "landing.groupsSection.KohoutQuote": "Quality software is the cornerstone of the success of any business, regardless of its size.",
      "landing.groupsSection.LifeInSpainQuote": "Join us on this exciting journey and discover the endless possibilities of Life in Spain.",
      "landing.groupsSection.YourTeam": "Your team",


      "landing.CTASection.wantToCooperate": "CAN WE DO ANYTHING FOR YOU?",
      "landing.CTASection.dontHesitate": "We can handle even the most demanding requirements.",
      "landing.CTASection.contactUs": "Contact us",
      "landing.BlogSection.description": "The latest news, articles and interesting information from professional circles or events around our group.",
      "landing.BlogSection.displayOlderPosts": "Display older posts",
      "landing.BlogSection.reading": "reading",
      "landing.leaderShipSection.title": "Meet our leadership",
      "landing.leaderShipSection.description": "Responsible persons of the given divisions.",
      "landing.leaderShipSection.realEstateDescription": "Real estate division",
      "landing.leaderShipSection.investmentDescription": "Development",

      "landing.results.smallTitle": "You can count on us.",
      "landing.results.title": "You can count on us.",
      "landing.results.description": "Our customers have recorded a positive increase in customer satisfaction and their sales numbers.",
      "landing.results.stat1": "Active users",
      "landing.results.stat2": "Countries around the globe",
      "landing.results.stat3": "Customer satisfaction",
      "landing.results.stat4": "Issues resolved",
      "landing.values.trust": "TRUST",
      "landing.values.trustDes": "Trust is the foundation of every successful partnership. For us, a handshake is more than a contract.",
      "landing.values.personalAproach": "PERSONAL APPROACH ",
      "landing.values.personalAproachDes": "We are one of you. Let us design a professional solution tailored to your current life or business situation.",
      "landing.values.quality": "QUALITY ",
      "landing.values.qualityDes": "We prefer quality over quantity. A maximally satisfied client is our best advertisement. ",
      "landing.values.innovation": "INNOVATION ",
      "landing.values.innovationDes": "We are constantly trying to improve our services and expand the existing portfolio. We listen to you and keep up with the times.", 
      "landing.values.innovationDesBigRes": "We are constantly trying to improve our services and expand the existing portfolio. We listen to you and keep up with the times.",
      "landing.values.personalAproachDesBigRes": "We are one of you. Let us design a professional solution tailored to your current life or business situation.",
      "landing.testimonials": "Testimonials",
      "landing.testimonials.title": "See what our clients have to say.",
      "landing.testimonials.subTitle": "Satisfaction of our clients and business partners is our priority.",
      "footer.navigation": "Navigation",
      "footer.externalLinks": "External Links",
      "footer.headquaters": "Headquaters",
      "footer.contactAdress": "Contact adress",
      "footer.prague": "Prague",
      "footer.czechia": "Czech Republic",
      "footer.subscribeToNewsTitle": "SUBSCRIBE TO OUR NEWSLETTER ",
      "footer.subscribeToNewsDescription": "The latest news, articles, and resources, sent to your inbox.",
      "footer.subscribeToNewsPlaceholder": "Enter your email",
      "footer.subscribe": "Subscribe",
      "notFound.error404": "404 error",
      "notFound.title": "Page not found",
      "notFound.description": "Sorry, we couldn’t find the page you’re looking for.",
      "notFound.returnHome": "Go back home",
      "contact.title": "Get to know us personally",
      "contact.options.title": "Service type",
      "contact.options.finance": "Finance",
      "contact.options.realEstate": "Real estate",
      "contact.options.investment": "Investments",
      "contact.options.software": "Software",
      "contact.anywhere" : "Anywhere we can agree to meet...",
      "contact.subTitle": "Based on the type of request, the relevant professional will contact you.",
      "contact.title2": "Where to find us",
      "contact.subtitle2": "Location of our offices, where you can arrange a meeting with us.",
      "contact.form.infoTitle": "Contact information",
      "contact.form.infoSubTitle": "Contact us by filling out the form, or use the contact details below. We will be happy to contact you as soon as possible.",
      "contact.form.title": "Send us a message",
      "contact.form.firstName": "First name",
      "contact.form.lastName": "Last name",
      "contact.form.email": "Email",
      "contact.form.phone": "Phone",
      "contact.form.optional": "Optional",
      "contact.form.subject": "Subject",
      "contact.form.message": "Message",
      "contact.form.maxCharacters": "Max. 500 characters",
      "contact.form.conditions1": "By selecting this, you agree to the",
      "contact.form.conditions2": "privacy policy",
      "contact.form.privacyPolicy": "Privacy policy",
      "contact.form.conditions3": "and",
      "contact.form.conditions4": "terms and conditions",
      "contact.form.termsAndConditions": "Terms and conditions",
      "contact.form.submit": "Submit",
      "contact.form.submitting": "Submitting",
      "contact.newsletter.title": "Sign up for our newsletter",
      "contact.newsletter.personalData1": "We care about the protection of your data.",
      "contact.newsletter.personalData2": "Read our",
      "contact.newsletter.personalData3": "Privacy Policy.",
      "legal.privacyDescription": "Detail of privacy policy document",
      "legal.conditionsDescription": "Detail of terms and conditions document",
      "legal.legalDocuments": "Legal documents",
      "validation.required": "Required",
      "validation.max500Char": "Message can contain max. 500 characters",
      "validation.invalidEmail": "Invalid e-mail address",
      "Thank you for submitting your message.": "Thank you for submitting your message.",
      "We will contact you soon.": "We will contact you soon.",
      "If you have any": "If you have any further wishes, please do not hesitate to contact us directly at:",
      "I understand": "I understand",
      "Unfortunately": "Unfortunately, there was an error processing it.",
      "We will ask you": "We will ask you to check your internet connection and try to send the message again or contact us directly.",
      "PrivateAgreementAll": EnglishPrivatePolicy,
    }
  },
  "cz": {
    "translation": {
      "common.chiefTechnologyOfficer": "technický ředitel",
      "common.chiefFinancilOfficer": "obchodní ředitelka",
      "common.chiefSalesOfficer": "obchodní ředitel",
      "common.CTO": "CTO",
      "common.home": "Domů",
      "common.conditions": "Podmínky",
      "landing.header.blog": "Blog",
      "landing.header.leadership": "Vedení",
      "landing.header.results": "Hodnoty",
      "landing.header.group": "Skupina",
      "landing.header.P4LFinance": "P4L Finance",
      "landing.header.P4LRealEstate": "Bydlíme s klidem",
      "landing.header.LifeInSpain": "Life in Spain",
      "landing.header.P4LInvestment": "P4L Development",
      "landing.header.cleverSolutionsTechnology": "Clever Solutions Technology",
      "landing.header.P4LFinanceDescription": "Finance jsou v žebříčku důležitosti na druhém místě hned po zdraví.",
      "landing.header.LifeInSpainDescription": "Najděte svůj vysněný domov v zahraničí a zažijte kouzlo španělského pobřežního bydlení na Costa del Sol.",
      "landing.header.P4LRealEstateDescription": "Neprodáváme nemovitosti, ale domovy našich klientů, které se mohou stát vaším domovem.",
      "landing.header.P4LInvestmentDescription": "Nechte peníze pracovat za Vás.",
      "landing.header.cleverSolutionsTechnologyDescription": "Kvalitní software, by měl být prioritou pro každý business.",
      "landing.header.contact": "Kontakt",
      "landing.hero.title1": "POSKYTUJEME PROFESIONÁLNÍ SLUŽBY S VYSOKOU PŘIDANOU HODNOTOU",
      "landing.hero.title2": "PRO KLIENTY I JEJICH BUSINESS",
      "landing.hero.description": "Vytváříme vlastní finanční ekosystém, ve kterém se našim klientům lépe žije a dýchá.",
      "landing.hero.learnMore": "Dozvědět se více",
      "landing.hero.groupMembers": "Členové Skupiny",
      "landing.groupsSection.P4LFinanceFullDescription": "Říká se, že finance jsou v žebříčku důležitosti na druhém místě hned po zdraví. Není tedy dobré tuto oblast zanedbávat či podceňovat, a už vůbec ne zcela ignorovat. Správně nastavené finanční portfolio Vám ušetří spoustu času, starostí i peněz. Přinese do Vašeho života více klidu a čistou hlavu v případě řešení nenadále vzniklých situací. Oslavujte s námi společné úspěchy na finančním poli.",
      "landing.groupsSection.LifeInSpainFullDescription": "Vstupte do živého světa Španělska! Zasazená podél úchvatného pobřeží Costa del Sol nabízí naše realitní společnost bránu do slunečného ráje. Od luxusních vil přímo u pláže po pohodlné středomořské apartmány, ponořte se do kouzla španělského života. S důrazem na zahraniční nemovitosti jsme tu, abychom Vám pomohli splnit sen vlastnit kousek španělského ráje. ",
      "landing.groupsSection.P4LRealEstateFullDescription": "Neprodáváme nemovitosti, ale domovy našich klientů, které se mohou stát vaším domovem. Domov je místem, kam se lidé těší, ať už jsou v práci anebo kdekoli jinde na světě. Při prodeji tedy postupujeme jako by se jednalo o náš vlastní domov. Naši klienti oceňují lidský přístup a profesionálně zvládnutý celý proces, který pro nás nekončí uvolněním kupní ceny prodávajícímu.",
      "landing.groupsSection.P4LInvestmentFullDescription": "Investování do nemovitostí je ve světě velice populární forma zhodnocování vydělaných peněz s filosofií: ,,Pracoval jsem, abych je vydělal, teď budou ony pracovat pro mě.’’ Nechte své peníze pracovat za Vás a přesvědčte se, že investování může být i skvělá zábava.",
      "landing.groupsSection.cleverSolutionsTechnologyFullDescription": "Specializujeme se na vytváření špičkových softwarových řešení šitých na míru jedinečným potřebám našich klientů. S využitím odborných znalostí našich externích partnerů a interních partnerů v rámci skupiny P4L-GROUP zajišťujeme, že každý projekt je proveden s přesností a profesionalitou. Nechte se překvapit naší precizností a kreativitou.",
      "landing.groupsSection.visitPage": "Navštívit stránku",
      "landing.groupsSection.interestedInInvestments": "Mám zájem o spolupráci",
      "landing.groupsSection.BerkovaQuote": "Kvalita myšlení odráží kvalitu života.",
      "landing.groupsSection.BerkaQuote": "V životě máš buď výmluvy anebo výsledky. Nemůžeš mít obojí.",
      "landing.groupsSection.HanusQuote": "Až vám někdo bude říkat, že je něco nemožné, ukažte mu, jak se to dělá.",
      "landing.groupsSection.KohoutQuote": "Kvalitní software je základním kamenem, na kterém stojí úspěch každého businessu, nezávisle na jeho velikosti.",
      "landing.groupsSection.LifeInSpainQuote": "Připojte se k nám na této vzrušující cestě a objevte nekonečné možnosti života ve Španělsku.",
      "landing.groupsSection.YourTeam": "Váš tým",

      "landing.CTASection.wantToCooperate": "MŮŽEME PRO VÁS NĚCO UDĚLAT?",
      "landing.CTASection.dontHesitate": "Poradíme si i s těmi nejnáročnějšími požadavky.",
      "landing.CTASection.contactUs": "Kontaktujte nás",
      "landing.BlogSection.description": "Nejnovější zprávy, články a zajímavé informace z odborných okruhů nebo dění okolo naší skupiny.",
      "landing.BlogSection.displayOlderPosts": "Zobrazit starší příspěvky",
      "landing.BlogSection.reading": "čtení",
      "landing.leaderShipSection.title": "Poznejte naše vedení",
      "landing.leaderShipSection.description": "Odpovědné osoby daných divizí.",
      "landing.leaderShipSection.realEstateDescription": "Realitní divize",
      "landing.leaderShipSection.investmentDescription": "Development",
      "landing.results.smallTitle": "Na nás se můžete spolehnout.",
      "landing.results.title": "Na nás se můžete spolehnout.",
      "landing.results.description": "Naši zákazníci zaznamenali pozitivní nárůst spokojenosti zákazníků a jejich prodejních čísel.",
      "landing.results.stat1": "Aktivních uživatelů",
      "landing.results.stat2": "Zemí z celého světa",
      "landing.results.stat3": "Spokojenost zákazníků",
      "landing.results.stat4": "Vyřešených problémů",
      "landing.values.trust": "DŮVĚRA",
      "landing.values.trustDes": "Důvěra je základem každého úspěšného partnerství. Podání ruky je pro nás víc než smlouva.",
      "landing.values.personalAproach": "OSOBNÍ PŘÍSTUP ",
      "landing.values.personalAproachDes": "Jsme jedním z Vás. Nechte si navrhnout profesionální řešení na míru odpovídající Vaší stávající životní či obchodní situaci.",
      "landing.values.personalAproachDesBigRes": "Jsme jedním z Vás. Nechte si navrhnout profesionální řešení na míru odpovídající Vaší stávající životní či obchodní situaci.",
      "landing.values.quality": "KVALITA ",
      "landing.values.qualityDes": "Dáváme přednost kvalitě před kvantitou. Maximálně spokojený klient je naší nejlepší reklamou.",
      "landing.values.innovation": "INOVACE ",
      "landing.values.innovationDes": "Neustále se snažíme vylepšovat naše </br> služby a rozšiřovat stávající portfolio. Nasloucháme Vám a držíme krok s dobou.",
      "landing.values.innovationDesBigRes": "Neustále se snažíme vylepšovat naše služby a rozšiřovat stávající portfolio. Nasloucháme Vám a držíme krok s dobou.",
      "landing.testimonials": "Reference",
      "landing.testimonials.title": "Podívejte se, co říkají naši klienti.",
      "landing.testimonials.subTitle": "Spokojenost našich klientů a obchodních partnerů je naší prioritou.",
      "footer.navigation": "Navigace",
      "footer.externalLinks": "Externí odkazy",
      "footer.headquaters": "Sídlo",
      "footer.contactAdress": "Kontaktní Adresa",
      "footer.prague": "Praha",
      "footer.czechia": "Česká republika",
      "footer.subscribeToNewsTitle": "ODEBÍREJTE NAŠE NOVINKY ",
      "footer.subscribeToNewsDescription": "Nejnovější zprávy, články a informace o akcích dostanete přímo do Vaší schránky.",
      "footer.subscribeToNewsPlaceholder": "Zadejte Váš email",
      "footer.subscribe": "Odebírat",
      "notFound.error404": "Chyba 404",
      "notFound.title": "Stránka nenalezena",
      "notFound.description": "Omlouváme se, nemohli jsme najít stránku kterou hledáte.",
      "notFound.returnHome": "Vrátit se domu",
      "contact.title": "Poznejte nás osobně",
      "contact.options.title": "Typ služby",
      "contact.options.finance": "Finance",
      "contact.options.realEstate": "Reality",
      "contact.options.investment": "Investice",
      "contact.options.software": "Software",
      "contact.anywhere" : "Kdekoli se domluvíme...",
      "contact.subTitle": "Na základě typu požadavku se Vám ozve příslušný profesionál.",
      "contact.title2": "Kde nás najdete",
      "contact.subtitle2": "Lokace našich kanceláří, kde si s námi můžete domluvit schůzku.",
      "contact.form.infoTitle": "Kontaktní informace",
      "contact.form.infoSubTitle": "Obraťte se na nás vyplněním formuláře, popřípadě využijte níže uvedené kontaktní údaje. Rádi se s Vámi v co nejbližší době spojíme.",
      "contact.form.title": "Pošlete nám zprávu",
      "contact.form.firstName": "Jméno",
      "contact.form.lastName": "Příjmení",
      "contact.form.email": "Email",
      "contact.form.phone": "Telefon",
      "contact.form.optional": "Nepovinné",
      "contact.form.subject": "Předmět",
      "contact.form.message": "Zpráva",
      "contact.form.maxCharacters": "Max. 500 znaků",
      "contact.form.conditions1": "Souhlasím s",
      "contact.form.conditions2": "ochranou osobních údajů",
      "contact.form.privacyPolicy": "Ochrana osobních údajů",
      "contact.form.conditions3": "a",
      "contact.form.conditions4": "obchodními podmínkami",
      "contact.form.termsAndConditions": "Obchodní podmínky",
      "contact.form.submit": "Odeslat",
      "contact.form.submitting": "Odesílání",
      "contact.newsletter.title": "Přihlašte se k našemu newsletter",
      "contact.newsletter.personalData1": "Záleží nám na ochraně Vašich osobních dat.",
      "contact.newsletter.personalData2": "Přečtěte si naše",
      "contact.newsletter.personalData3": "zásady ochrany osobních údajů.",
      "legal.privacyDescription": "Detail dokumentu o zpracování osobních údajů",
      "legal.conditionsDescription": "Detail dokumentu o Všeobecných obchodních podmínkách",
      "legal.legalDocuments": "Právní dokumenty",
      "validation.required": "Povinné",
      "validation.max500Char": "Zpráva může obsahovat max. 500 znaků",
      "validation.invalidEmail": "Neplatná e-mailová adresa",
      "Thank you for submitting your message.": "Děkujeme za Vaši zprávu.",
      "We will contact you soon.": "Brzy se Vám ozveme.",
      "If you have any": "Pokud máte další přání, neváhejte nás kontaktovat na přímo:",
      "I understand": "Rozumím",
      "Unfortunately": "Ale bohužel došlo k chybě při jejím zpracování.",
      "We will ask you": "Poprosíme Vás abyste si zkontrolovali připojení k internetu a zkusili zprávu poslat znovu nebo nás kontaktovali na přímo.",
      "PrivateAgreementAll": CzechPrivatePolicy,
    }
  }
}
export default translations;

