import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_MODAL_LEGAL } from '../actions/types';

import { useTranslation } from 'react-i18next';
import LanguageSelect from './LanguageSelect';

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function LegalSlideover() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    /*
    if(appUI.showLegalModal){
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
    else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  */
    function renderHeader() {
        if (appUI.modalLegalType === 'conditions') {
            return (
                <div className="inline-flex">
                    <div className="mt-1 h-full w-10 sm:w-12">
                        <LanguageSelect />
                    </div>
                    <div className="space-y-1  ">
                        <Dialog.Title className="text-lg inline-flex font-medium text-white"> {t('legal.legalDocuments')} </Dialog.Title>

                    </div>
                </div>

            )
        }
        else if (appUI.modalLegalType === 'private') {
            return (
                <div className="inline-flex">
                    <div className="mt-1 h-full w-10 sm:w-12">
                        <LanguageSelect />
                    </div>
                    <div className="space-y-1">
                        <Dialog.Title className="text-lg inline-flex font-medium text-white"> {t('legal.legalDocuments')} </Dialog.Title>

                    </div>
                </div>
            )
        }
    }



    function renderBody() {
        if (appUI.modalLegalType === 'conditions') {
            return (
                <>
                    <div className="p-5 text-base max-w-prose mx-auto lg:max-w-3xl sm:p-2 my-3 sm:my-6 md:p-0">
                        <h3 className=" text-3xl leading-6 font-extrabold tracking-tight text-gray-900 sm:text-4xl pb-2 mb-3 border-b border-gray-200">{t('contact.form.termsAndConditions')}</h3>
                        {appUI.language === 'en' ? <LegalContantTermsAndServicesCZ /> : <LegalContantTermsAndServicesEN />}
                    </div>
                </>
            )
        }
        else if (appUI.modalLegalType === 'private') {
            return (
                <>
                    <div className="p-5 text-base max-w-prose mx-auto lg:max-w-3xl sm:p-2  my-3 sm:my-6 md:p-0">
                       
                        <h3 className=" text-3xl leading-6 font-extrabold tracking-tight text-gray-900 sm:text-4xl pb-2 mb-3 border-b border-gray-200">{t('contact.form.privacyPolicy')}</h3>
                        {appUI.language === 'en' ? <LegalContentPrivateAgreementCZ /> : <LegalContentPrivateAgreementEN />}
                    </div>
                </>
            )
        }
    }

    function handleClose() {
        dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: false, legalType: '' } })

    }
    return (
        <>
            <Transition.Root show={appUI.showLegalModal} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={handleClose}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-6 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1">
                                                {/* Header */}
                                                <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-4 pt-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        {renderHeader()}
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="text-gray-400 hover:text-gray-500"
                                                                onClick={handleClose}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="border-b border-gray-200">
                                                        <div className=" sm:mx-11 pt-5">
                                                            <nav className="-mb-px flex space-x-6" x-descriptions="Tab component">
                                                                <p
                                                                    onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'private' } })}
                                                                    className={classNames(
                                                                        appUI.modalLegalType === 'private'
                                                                            ? 'border-white text-white'
                                                                            : 'border-transparent text-gray-300 hover:text-gray-100 hover:border-gray-300 hover:cursor-pointer',
                                                                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-xs sm:text-sm'
                                                                    )}
                                                                >
                                                                    {t('contact.form.privacyPolicy')}
                                                                </p>
                                                                <p
                                                                    onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'conditions' } })}
                                                                    className={classNames(
                                                                        appUI.modalLegalType === 'conditions'
                                                                            ? 'border-white text-white'
                                                                            : 'border-transparent text-gray-300 hover:text-gray-100 hover:border-gray-300 hover:cursor-pointer',
                                                                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-xs sm:text-sm'
                                                                    )}
                                                                >
                                                                    {t('contact.form.termsAndConditions')}
                                                                </p>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Divider container */}
                                                <div className="">

                                                    {renderBody()}
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default LegalSlideover;





const LegalContentPrivateAgreementCZ = () => <>
    <p className="text-xs sm:text-sm text-gray-500">
        Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2,zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264 (dále jen „P4L“), jakožto poskytovatel software, IT řešení a marketingu a provozovatel webového portálu www.p4life.cz a www.p4life-rk.cz,při správě a zpracování osobních údajů svých klientů a spolupracujících osob plně respektuje zásady jejich ochrany a v souladu s platnými právními předpisy níže informuje subjekty osobních údajů o jejich právech, důvodech zpracování a další podstatné informace týkající se zpracování osobních údajů.

        <br /><br />Společnost P4L, s.r.o. se v plném rozsahu řídí pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“) a v souladu s tímto Nařízením přísně dodržuje všechny nastavené postupy.

        <br /><br />Pokud se jakákoli část tohoto dokumentu bude zdát nesrozumitelná nebo vyplyne-li potřeba dalšího podrobnějšího vysvětlení některé problematiky související se zpracováním osobních údajů, je možné obrátit se na naši emailovou adresu:obchod@p4life.cz.
    </p>

    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>Obsah:</span>

        <br /><br />1. Správce osobních údajů a jeho identifikace – kdo to je.
        <br />2. Rozsah zpracování osobních údajů.
        <br />3. Doba, po kterou jsou osobní údaje zpracovány.
        <br />4. Kdo má k osobním údajům přístup, komu jsou poskytovány.
        <br />5. Automatizované zpracování osobních údajů.
        <br />6. Jaká jsou práva subjektu údajů.
        <br />7. Uplatnění práv subjektů údajů – klientů společnosti P4L.
        <br />8. Souhlas se zpracováním osobních údajů.

    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>1. Správce osobních údajů a jeho identifikace – kdo to je </span><br /><br />

        Správcem je osoba, která disponuje s osobními údaji subjektu údajů a zároveň určuje účel a prostředky jejich zpracování. Společnost P4L je správcem osobních údajů, jelikož shromažďuje osobní údaje zájemců o porovnání nabídek hypoték na základě zadaných kritérií a dále pro tyto zájemce zajišťuje možnost zpracování hypoteční analýzy hypotečním specialistou s cílem rychlého a efektivního vyřízení žádosti o hypotéku.

        <br /><br />Kontaktní údaje a totožnost správce:

        <br /><br />Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, e-mail: obchod@p4life.cz.

        <br /><br />Pověřenec pro ochranu osobních údajů nebyl u Správce jmenován. Správce nemá zástupce pro plnění povinností ve smyslu Nařízení.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>  2. Rozsah a kategorie zpracování osobních údajů.</span>

        <br /><br />Společnost P4L zpracovává pouze ty osobní údaje, které jsou nezbytné pro řádnéposkytování služeb, plnění zákonných povinností a též k ochraně zájmů společnosti P4L. Shromažďujeme osobní údaje zájemců o naše služby nebo potenciálních zájemců, kteří nám ke zpracování osobních údajů a k oslovení udělili souhlas.

        <br /><br />Kategorie osobních údajů, které společnost P4L zpracovává:

        <br /><br />a) identifikační údaje – jméno, příjmení, datum narození, adresa bydliště, podpis,u podnikatelských subjektů IČ a adresa sídla.

        <br /><br />b) kontaktní údaje – e-mailová adresa a telefonní číslo, adresa místa bydliště – jméno, příjmení, datum narození, adresa bydliště, podpis, u podnikatelských subjektů IČ a adresa sídla.

        <br /><br />c) sociodemografické údaje (věk, příjmy, splátky úvěru, počet dětí) – tyto údaje společnost P4L zpracovává pouze v případě, kdy si subjekt údajů sám dobrovolně


        zvolí možnost rozšířených kritérií pro srovnání hypotéky na webovém portáluwww.p4life.cz a www.p4life-rk.cz, poskytnutí těchto údajů není povinné a záleží pouze na volbě subjektu údajů, zda má zájem o podrobnější výsledek srovnání hypotéky.

        <br /><br />Zpracování osobních údajů bez nutnosti udělení souhlasu:

        <br /><br />a) pro účely smluvního plnění

        <br />– poskytnutí osobních údajů pro účely srovnání a kalkulaci hypotéky na základě zadaných kritérií zájemce je smluvním požadavkem společnosti P4L.

        <br /><br />b) zpracování na základě plnění zákonných povinností

        <br />– společnost P4L zpracovává také osobní údaje, pokud jí tuto povinnost přikazuje zákon.

        <br /><br />c) zpracování na základě oprávněných zájmů společnosti P4L
        <br />– zejména pro účely obhajoby právních nároků společnosti P4L

        Zpracování osobních údajů s udělením souhlasu zájemce 
        <br />– za účelem nabídky finančního produktu 
        <br /><br/>– hypotéky:
        <br />– udělení souhlasu zájemcem je dobrovolné, zároveň však nezbytné, aby jej mohl dále kontaktovat hypoteční specialista (Poradce) za účelem zpracování individuální nabídky, hypoteční analýzy a informování zájemce o finančních produktech 
        <br />– hypotékách dle kritérií zadaných zájemcem do srovnávače/kalkulačky hypoték na webovém portálu www.p4life.cz a www.p4life- rk.cz.

        <br />– na základě uděleného souhlasu pro účely nabídky finančního produktu 
        <br /> –hypotéky může být zájemce kontaktován formou e-mailové zprávy nebo telefonicky, další komunikace probíhá dle preferencí zájemce.

        <br />– osobní údaje mohou být za účelem zpracování nabídky hypotéky poskytnuty hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se zájemcem finanční služby,



        <br />– bez uděleného souhlasu nemůže být zájemce informován ani kontaktován s nabídkou individuálního hypotečního produktu, nelze s ním o možnostech sjednání hypotéky či jiného finančního produktu jednat, nabídka finančních produktů nemůže být více zpřesňována,

        <br />– zájemce může udělený souhlas kdykoli odvolat a to písemně na adresuobchod@p4life.cz, písemně dopisem na korespondenční adresu společnosti P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 3. Doba, po kterou jsou osobní údaje zpracovány.</span>
        <br /><br />Osobní údaje klientů společnost P4L zpracovává po dobu, po kterou svým klientům poskytuje své služby nebo po dobu trvání smlouvy. Po ukončení smluvního vztahu a po vypořádání veškerých závazků z takové smlouvy plynoucích a jinak s ní souvisejících uchovává společnost P4L osobní údaje klientů po dobu nezbytně nutnou, která je vyžadována dle příslušných právních předpisů, např. dle zákona o účetnictví, zákona o archivnictví a evidenci, zákona o dani z přidané hodnoty, atd. a dle obecných i speciálních promlčecích lhůt.U udělených souhlasů se zpracováním pro účely nabídky finančního produktu je doba uchování osobních údajů 3 roky od udělení souhlasu.Společnost P4L se při uchování osobních údajů řídí zásadou minimalizace údajů, v okamžiku, kdy odpadne účel zpracování osobních údajů nebo uplyne doba, po kterou je společnost P4L povinna osobní údaje uchovávat, osobní údaje jsou zcela vymazány ze systémů, případně vhodným způsobem anonymizovány. Osobní údaje tak nikdy nejsou zpracovávány nebo uchovávány déle, než je nezbytně nutné.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 4. Kdo má k osobním údajům přístup, komu jsou poskytovány.</span>
        <br /><br />Osobní údaje klientů jsou zpřístupněny společnosti P4L jakožto jejich správci, dálepověřeným zaměstnancům společnosti P4L a smluvním zpracovatelům, se kterými má společnost P4L uzavřenou Smlouvu o zpracování osobních údajů, na základě které jsou tito smluvní zpracovatelé povinni osobní údaje zpracovávat v souladu s Nařízením. Smluvními zpracovateli jsou zejména hypoteční specialisté, tj. Poradci, poskytovatelé IT služeb, účetní a daňoví poradci.



        <br />Osobní údaje zpracovávané na základě uděleného souhlasu za účelem nabídky finančního produktu mohou být v souladu s uděleným souhlasemposkytnuty/předány jinému správci, a to konkrétnímu hypotečnímu specialistovi (dále jen „Poradce“), který má se společností P4L uzavřenou Smlouvu o spolupráci a dle zákona č. 257/2016 Sb., zákon o spotřebitelském úvěru, je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

        <br />Společnost P4L svým klientům garantuje, že osobní údaje, které zpracovává na základě uděleného souhlasu za účelem nabídky finančního produktu, nejsou v žádném případě předávány žádným externím call centrům ani marketingovýmspolečnostem zajišťujícím nejrůznější marketingové kampaně, vždy se jedná o konkrétní osobu hypotečního specialisty, která je smluvním partnerem společnosti P4L.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 5. Automatizované zpracování osobních údajů.</span>
        <br /><br />Součástí poskytování služeb společnosti P4L je i automatizované zpracování osobních údajů. Při automatizovaném zpracování osobních údajů jsou používány automatické informační systémy, např. elektronické kalkulačky, software, IT aplikace. Cílem automatizovaného zpracování osobních údajů je rychléa individuální poskytnutí služby dle specifických požadavků a potřeb klientů.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 6. Jaká jsou práva subjektu údajů.</span>
        <br /><br />a) Právo na informace týkající se zpracování osobních údajůKaždý subjekt zpracování osobních údajů má právo znát totožnost a kontaktní údaje na správce osobních údajů, jeho zástupce i na případné pověřence pro ochranu osobních údajů, dále má právo znát účel a právní základ zpracování osobních údajů, právo znát příjemce nebo kategorie příjemců osobních údajů, informace o předání osobních údajů do třetích zemí, dobu uložení osobních údajů, informaci o právu na odvolání souhlasu a o dalších právech subjektu údajů, možnost podat stížnost u Úřadu na ochranu osobních údajů, informaci, zda je poskytování osobních údajů zákonný či smluvní požadavek, informování o automatizovaném rozhodování a profilování.



        <br /><br />b) Právo na přístup k osobním údajům

        <br />Právo klienta požadovat od společnosti P4L, jakožto správce, potvrzení, zda jeho osobní údaje jsou či nejsou zpracovávány. Pokud zpracovávány jsou, tak máklient, jakožto subjekt údajů, právo získat přístup k těmto osobním údajům a k informacím o účelu jejich zpracování, kategoriích dotčených osobních údajů, příjemcích nebo kategoriích příjemců, kterým jsou, byly nebo mají být osobní údaje zpřístupněny, dobu zpracování osobních údajů, existence práva požadovat opravu, výmaz, omezení a možnosti vznést námitku proti zpracování, informace o právu podat stížnost u Úřadu pro ochranu osobních údajů, informace o zdroji osobních údajů, informace, zda dochází k automatizovanému rozhodování a profilování osobních údajů, informace o zárukách při předávání osobních údajů do třetích zemí nebo mezinárodním organizacím.

        <br />Subjekt údajů má právo požadovat od společnosti P4L kopie zpracovávaných osobních údajů. Při opakovaných a zjevně nepřiměřených žádostech je společnostP4L oprávněna účtovat klientům přiměřený poplatek na úhradu administrativních nákladů za pořízení kopie.

        <br />Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na adresu sídla nebo na e-mailovou adresu společnosti P4L.

        <br /><br />c) Právo na opravu osobních údajů

        <br />Subjekt údajů má právo žádat, aby společnost P4L bez zbytečného odkladu opravila nepřesné a doplnila neúplné osobní údaje, které se jej týkají. Základem uplatnění tohoto práva je sdělení skutečnosti vedoucí k opravě či doplnění společnosti P4L.

        <br /><br />d) Právo na výmaz osobních údajů

        <br />V případech, kdy pominul účel zpracování osobních údajů, byl odvolán souhlas ke zpracování osobních údajů a v dalších případech, které jsou podrobně uvedeny v čl. 17 odst. 1 písm. a) až f) Nařízení, je společnost P4L na základě Vaší žádosti povinna osobní údaje žádajícího klienta smazat. Pokud však není splněna zákonná podmínka pro výmaz, má společnost P4L právo si osobní údajeponechat. Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na korespondenční adresu společnosti P4L – Komenského 446/6,Jablonec nad Nisou, 466 01 nebo na e-mailovou adresu společnosti P4L.



        <br /><br />e) Právo na omezení zpracování osobních údajů

        <br />Na žádost klienta je společnost P4L povinna omezit zpracování jeho osobních údajů jen na nejnutnější zákonné důvody, pokud jsou splněny podmínky dle čl. 18 Nařízení. V obecné rovině lze toto právo uplatnit v případech, kdy není jasné, zda a kdy se budou muset osobní údaje odstranit.

        <br /><br />f) Právo vznesení námitky proti zpracování osobních údajů

        <br />V případech, kdy klient dojde ke zjištění, že společnost P4L zpracovává jeho osobní údaje v rozporu se zákonem nebo v rozporu s ochranou soukromého a osobního života klienta, má právo obrátit se přímo na společnost P4L s toutonámitkou, aby došlo k co nejrychlejšímu odstranění závadného stavu nebo k dostatečnému zdůvodnění nastalé situace. Námitky lze vznášet i proti automatizovanému rozhodování/profilování.

        <br /><br />g) Právo na přenositelnost údajů

        <br />Klient může požadovat, aby jeho osobní údaje, které společnosti P4L udělil, byly předány jinému správci dle požadavku klienta. Osobní údaje budou přeneseny v běžně používaném odpovídajícím formátu, nebude-li požadavku klienta bránit zákonná či jiná překážka.

        <br /><br />h) Právo podat podnět/stížnost na Úřad pro ochranu osobních údajů

        <br />S jakýmkoli podnětem či stížností týkající se zpracování osobních údajů se klient může obrátit přímo na Úřad pro ochranu osobních údajů, jakožto na dozorový orgán.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 7. Uplatnění práv subjektů údajů – klientů společnosti P4L.</span>

        <br /><br />Jednotlivá práva uvedená v čl. 6 tohoto informačního dokumentu mohou subjekty údajů uplatnit u správce, společnosti P4L buď e-mailovou zprávou na adresu:obchod@p4life.cz nebo písemně dopisem na adresu společnosti P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

        <br /><br />Žádosti subjektů údajů je společnost P4L povinna vyřešit nejpozději ve lhůtě 1 měsíce ode dne přijetí žádosti. V odůvodněných případech zejména s ohledem na rozsah žádosti je společnost P4L oprávněna lhůtu



        k vyjádření prodloužit na 2 měsíce, o nutnosti prodloužení společnost P4L subjektúdajů včas informuje.

        <br /><br />Dokument je pravidelně aktualizován, datum poslední aktualizace 25. 5. 2018.

        <br /><br />Souhlas se zpracováním osobních údajů

        <br />Tímto uděluji společnosti P4L s. r. o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, zastoupena jednatelem Lukášem Berkou, email: obchod@p4life.cz (dále jen „Správce“) souhlas se zpracováním osobních údajův rozsahu:

        <br />- jméno a příjmení
        <br />- emailová adresa
        <br />- telefonní číslo
        <br />- adresa trvalého bydliště
        <br />- věk žadatele
        <br />- měsíční příjem
        <br />- měsíční splátky úvěru
        <br />- členové domácnosti
        <br />- počet žadatelů
        <br />- platební historie

        <br /><br />(dále jen „Osobní údaje“)

        <br />za účelem:

        <br /><br />Osobní údaje budou použity za účelem zasílání informací, produktů, služeb, novinek a aktualit týkajících se finančního trhu tj. newsletterů a dále ke zpracování nabídky finančních produktů dle kritérií zadaných subjektemúdajů do srovnávače / kalkulačky hypoték na webovém portálu Správcehttp://www.p4life.cz a www.p4life-rk.cz, ke kontaktování subjektu údajůe-mailem či telefonicky a k dalšímu jednání o finančních produktech s hypotečním specialistou.

        <br /><br />Osobní údaje jsou zpracovávány 3 roky a může na základě jejich zpracování docházet k automatizovanému zpracování či profilování.



        <br /><br />Osobní údaje mohou být za účelem zpracování nabídky finančního produktu poskytnuty / předány jinému správci, a to hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

        <br /><br />Dále beru na vědomí, že tentosouhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresuSprávce nebo na e-mailovou adresu obchod@p4life.cz, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu.

        <br /><br />Více informací o zpracování mých osobních údajů je uvedeno v informačním dokumentu, který je k dispozici na webové adrese www.p4life.cz a www.p4life- rk.cz.

        <br /><br />V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy obchod@p4life.cz.
    </p>
</>;

const LegalContentPrivateAgreementEN = () => <>
    <p className="text-xs sm:text-sm text-gray-500">
        The company P4L, s.r.o., IČ 04534999, with its registered office at Korunní 588/4, 120 00 Prague 2, registered in the Commercial Register kept by the Municipal Court in Prague, Section C, File 249264 (hereinafter "P4L"), as a provider of software, IT solutions and marketing and operator of the web portal www.p4life.cz and www.p4life-rk.cz, in the management and processing of personal data of its clients and cooperating persons fully respects the principles of their protection and in accordance with applicable law below informs personal data subjects about their rights , reasons for processing and other relevant information concerning the processing of personal data.

        <br /><br />The company P4L, s.r.o. shall be governed in full by the rules laid down in Regulation (EU) No 2016/679 of the European Parliament and of the Councilof 27 April 2016, the General Regulation on Personal Data Protection (hereinafter referred to as the “Regulation”) and strictly adheres to all established procedures in accordance with this Regulation.

        <br /><br />If any part of this document seems incomprehensible or if the need for further detailed explanation of some issues related to the processing of personal data arises, it is possible to contact our email address: obchod@p4life.cz.
    </p>

    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>Contents:</span>

        <br /><br />1. Personal data controller and its identification - who it is.
        <br />2. Scope of personal data processing.
        <br />3. The period for which personal data is processed.
        <br />4. Who has access to personal data, to whom it is provided.
        <br />5. Automated processing of personal data.
        <br />6. What are the rights of the data subject.
        <br />7. Exercising the rights of data subjects - clients of P4L.
        <br />8. Consent to the processing of personal data.

    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>1. Personal data controller and its identification - who it is. </span><br /><br />

        The controller is a person who disposes of the personal data of the data subject and at the same time determines the purpose and means of their processing. P4L is a personal data controller, as it collects personal data of those interested in comparing mortgage offers on the basis of specified criteria and also provides these applicants with the opportunity to have a mortgage analysis processed by a mortgage specialist in order to process a mortgage application quickly and efficiently.

        <br /><br />Contact details and administrator identity:

        <br /><br />The company P4L, s.r.o., IČ 04534999, with its registered office at Korunní 588/4, 120 00 Prague 2, entered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 249264, e-mail: obchod@p4life.cz.

        <br /><br />The Data Protection Officer has not been appointed by the Administrator. The administrator does not have a representative for the performance of duties within the meaning of the Regulation.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>2. Scope of personal data processing.</span>

        <br /><br />P4L processes only those personal data that are necessary for the proper provision of services, fulfillment of legal obligations and also to protect the interests of P4L. We collect personal data of those interested in our services or potential applicants who have given us consent to the processing of personal data and to contact us.

        <br /><br />Categories of personal data processed by P4L:

        <br /><br />a) identification data - name, surname, date of birth, address of residence, signature, ID number for business entities and registered office address.

        <br /><br />b) contact details - e-mail address and telephone number, address of the place of residence - name, surname, date of birth, address of residence, signature, ID number of business address and registered office address.

        <br /><br />c) sociodemographic data (age, income, loan repayments, number of children) - these data are processed by P4L only if the data subject voluntarily chooses the option of extended criteria for mortgage comparison on the web portalswww.p4life.cz and www.p4life- rk.cz, the provision of these data is not mandatory and it only depends on the choice of the data subject whether he is interested in a more detailed result of the mortgage comparison.




        <br /><br />Processing of personal data without the need for consent:

        <br /><br />a) for the purposes of contractual performance
        <br />- the provision of personal data for the purposes of comparison and calculation of the mortgage on the basis of the entered criteria of the applicant is a contractual requirement of P4L.



        <br /><br />b) processing on the basis of compliance with legal obligations
        <br />- P4L also processes personal data if required to do so by law.



        <br /><br />c) processing based on the legitimate interests of P4L
        <br />- in particular for the purposes of defending P4L's legal claims Processing of personal data with the consent of the applicant

        Processing of personal data with the consent of the applicant
        <br />- for the purpose of offering a financial product
        <br /><br/> – mortgages:
        <br />- granting the applicant's consent is voluntary, but at the same time it is necessary for him to be contacted by a mortgage specialist (Advisor) in order to process an individual offer, mortgage analysis and inform the applicant about financial products
        <br />- mortgages according to criteria specified by the applicant in the mortgage comparator / calculator on the web portal www. p4life.cz and www.p4life- rk.cz.

        <br />- on the basis of the granted consent for the purposes of offering a financial product 
        <br/>- mortgage, the applicant may be contacted by e-mail or by telephone, further communication takes place according to the applicant's preferences.

        <br />- personal data may be provided to a mortgage specialist (hereinafter referred to as the “Adviser”) for the purpose of processing the mortgage offer, who has a Cooperation Agreement with the Administrator and is authorized to provide and negotiate financial services with the applicant,



        <br />- without the consent, the applicant cannot be informed or contacted with the offer of an individual mortgage product, it is not possible to negotiate with him about the possibilities of arranging a mortgage or other financial product, the offer of financial products cannot be further specified,

        <br />- the interested party may revoke the granted consent at any time in writing to the address obchod@p4life.cz, in writing by letter to the correspondence address of the company P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 3. The period for which personal data are processed.</span>
        <br /><br />P4L processes personal client data for the period during which it provides its clients with its services or for the duration of the contract. Upon termination of the contractual relationship and after settlement of all obligations arising from such a contract and otherwise related to it, P4L retains the personal data of clients for the time strictly necessary, as required by applicable law, such as the Accounting Act, the Archives and Records Act, of the Value Added Tax Act, etc. and according to general and special limitation periods. at the moment when the purpose of personal data processing ceases or the period during which P4L is obliged to store personal data expires, the personal data are completely deleted from the systems, or anonymized in a suitable manner. Thus, personal data is never processed or stored for longer than is absolutely necessary.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 4. Who has access to personal data to whom it is provided.</span>
        <br /><br />Clients' personal data is made available to P4L as their administrator, P4L sub-authorized employees and contract processors with whom P4L has a Personal Data Processing Agreement, on the basis of which these contract processors are obliged to process personal data in accordance with the Regulation. Contract processors are mainly mortgage specialists, ie consultants, IT service providers, accounting and tax consultants.

        <br />Personal data processed on the basis of the granted consent for the purpose of offering a financial product may be provided / transferred in accordance with the granted consent to another administrator, namely a specific mortgage specialist (hereinafter "Advisor") who has a Cooperation Agreement with P4L and Act no. 257/2016 Coll., The Consumer Credit Act, is entitled to provide and negotiate with the financial service data subject.

        <br />P4L guarantees to its clients that the personal data it processes on the basis of the granted consent for the purpose of offering a financial product are in no case passed on to any external call centers or marketing companies providing various marketing campaigns, it is always a specific person mortgage specialist who is a contract partner of P4L.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 5. Automated processing of personal data.</span>
        <br /><br />The provision of P4L's services also includes automated processing of personal data. Automatic information systems, such as electronic calculators, software, IT applications, are used in the automated processing of personal data. The goal of automated personal data processing is the fast individual provision of the service according to the specific requirements and needs of clients.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'>6. What are the rights of the data subject.</span>
        <br /><br />a) The right to information concerning the processing of personal dataEach subject of personal data processing has the right to know the identity and contact details of the personal data controller, his representative and any data protection commissioners, he also has the right to know the purpose and legal basis of personal data processing, the right know the recipients or categories of recipients of personal data, information on the transfer of personal data to third countries, the period of storage of personal data, information on the right to withdraw consent and other rights of the data subject, the possibility to file a complaint with the Office for Personal Data Protection; data legal or contractual requirement, information on automated decision-making and profiling.



        <br /><br />b) Right of access to personal data

        <br />The client's right to request confirmation from P4L, as the administrator, whether or not his personal data is being processed. If they are processed, the client, as a data subject, has the right to access this personal data and information about the purpose of their processing, the categories of personal data concerned, the recipients or categories of recipients to whom the personal data are, have been or are to be disclosed. , the existence of the right to request rectification, deletion, restrictions and the possibility to object to processing, information on the right to file a complaint with the Office for Personal Data Protection, information on the source of personal data, information on whether there is automated decision-making and profiling of personal data, information on guarantees in transfer of personal data to third countries or international organizations.

        <br />The data subject has the right to request copies of the processed personal data from P4L. In the event of repeated and manifestly disproportionate requests, P4L is entitled to charge clients a reasonable fee to cover the administrative costs of obtaining a copy.

        <br />In addition to sending a written request to the registered office or e-mail address of P4L, data subjects may exercise this right.

        <br /><br />c) The right to correct personal data

        <br />The data subject has the right to request that P4L correct inaccurate and supplement incomplete personal data concerning him without undue delay. The basis for the exercise of this right is the communication of the facts leading to the correction or addition of P4L.

        <br /><br />d) The right to delete personal data

        <br />In cases where the purpose of the processing of personal data has lapsed, the consent to the processing of personal data has been revoked and in other cases, which are detailed in Article 17 (1) (a). a) to f) of the Regulation, P4L is obliged to delete the personal data of the requesting client based on your request. However, if the legal condition for deletion is not met, P4L has the right to keep personal data. In addition to sending a written request to the correspondence address of P4L - Komenského 446/6, Jablonec nad Nisou, 466 01, or to the e-mail address of P4L, data subjects may exercise this right.



        <br /><br />e) The right to restrict the processing of personal data

        <br />At the client's request, P4L is obliged to limit the processing of his personal data only to the most necessary legal reasons, if the conditions under Article 18 of the Regulation are met. In general, this right can be exercised in cases where it is not clear whether and when personal data will have to be deleted.

        <br /><br />f) The right to object to the processing of personal data

        <br />In cases where the client finds that P4L processes his personal data in violation of the law or in violation of the protection of the client's private and personal life, he has the right to contact P4L directly with this objection in order to eliminate the defective condition as soon as possible. or to adequately justify the situation. Objections can also be raised against automated decision-making / profiling.

        <br /><br />g) The right to data portability

        <br />The Client may request that his personal data provided to P4L be passed on to another administrator at the Client's request. Personal data will be transferred in the commonly used appropriate format, unless the client's request is hindered by legal or other obstacles.

        <br /><br />h) The right to submit a complaint / complaint to the Office for Personal Data Protection

        <br />The client may contact the Office for Personal Data Protection, as the supervisory body, with any suggestion or complaint concerning the processing of personal data.
    </p>
    <p className="text-xs sm:text-sm text-gray-500">
        <br /> <span className='text-base font-semibold text-gray-700'> 7. Exercising the rights of data subjects - clients of P4L.</span>

        <br /><br />Individual rights listed in Article 6 of this information document may be exercised by data subjects with the administrator, P4L either by e-mail to: obchod@p4life.cz or in writing to the address of P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

        <br /><br />P4L is obliged to resolve data subjects' requests no later than within 1 month from the date of receipt of the request. In justified cases, especially with regard to the scope of the request, P4L is entitled to extend the deadline for comments to 2 months, P4L will inform the data subjects in time about the need for the extension.


      

        <br /><br />The document is regularly updated, date of last update May 25, 2018.

        <br /><br />Consent to the processing of personal data

        <br />I hereby grant P4L s. R. O., IČ 04534999, with its registered office at Korunní 588/4, 120 00 Prague 2, entered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 249264, represented by the managing director Lukáš Berka, email: obchod@p4life.cz ( hereinafter referred to as the "Administrator") consent to the processing of personal data to the extent of:

        <br />- name and surname
        <br />- email address
        <br />- telephone number
        <br />- permanent address
        <br />- age of the applicant
        <br />- monthly income
        <br />- monthly loan repayments
        <br />- household members
        <br />- number of applicants
        <br />- ayment history

        <br /><br />(hereinafter referred to as "Personal Data")

        <br />for a purpose:

        <br /><br />Personal data will be used for the purpose of sending information, products, services, news and updates related to the financial market, ie newsletters, and also to process the offer of financial products according to the criteria specified by the data subject in the mortgage comparator / calculator on the web portal Administratorhttp: //www.p4life. cz and www.p4life-rk.cz, to contact the subject by e-mail or telephone and to further negotiate financial products with a mortgage specialist.

        <br /><br />Personal data are processed for 3 years and based on their processing, automated processing or profiling can take place.



        <br /><br />Personal data may be provided / transferred to another administrator for the purpose of processing the offer of a financial product, namely a mortgage specialist (hereinafter referred to as the “Advisor”) who has a Cooperation Agreement with the Administrator and is entitled to provide and negotiate financial services with the data subject.

        <br /><br />I further acknowledge that I give this consent freely and voluntarily and I may revoke it at any time in the form of written information sent to the Administrator's address or to the e-mail address obchod@p4life.cz, even before the expiration of the period for which I gave my consent. . Withdrawal of this consent does not affect the lawfulness of the processing of personal data in the period prior to the withdrawal of consent.

        <br /><br />More information about the processing of my personal data is provided in the information document, which is available at www.p4life.cz and www.p4life-rk.cz.

        <br /><br />In case of questions regarding the processing of my personal data, I can contact the administrator of personal data, via the email address obchod@p4life.cz.
    </p>
</>;
const LegalContantTermsAndServicesCZ = () => <>
    <p className="text-xs sm:text-sm text-gray-500 ">
        <span className='text-base font-semibold text-gray-700'> 1. Úvod</span><br />
        1.1 Společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru, IT řešení a marketingu (registrované obchodní značky) a provozuje web www.p4life.cz svým smluvním
        partnerům (odběratelům, smluvním poradcům), služby poskytuje za níže uvedených podmínek. Web www.p4life.cz a služby s ním související slouží ke zprostředkování obchodních příležitostí (kontaktů), poptávek a nabídek a zpřístupňuje kontakty za účelem navázání spolupráce pro Uživatele. Poptávkou se rozumí odeslaný strukturovaný požadavek od uživatele s cílem získat obecné informace ohledně hypotečního úvěru (dále jen „hypotéky“) díky propojení (telefonickém i osobním) s Poradci.<br />
        <br /> 1.2 Společnost P4L s.r.o. poskytuje software a registrovanou obchodní značku a marketingové služby se značkou související. Tyto služby jsou poskytovány odběratelům (smluvním partnerům). Stejně tak celý web je provozován na základě smluvně daného vztahu (případně placených licencí) pro odběratele. Těmito odběrateli (smluvními partnery) jsou společnosti nebo jednotlivé osoby podnikající v oblasti finančního poradenství s oprávněním zprostředkovávat spotřebitelské úvěry (hypotéky) dle zákona č. 257/2016 Sb. o spotřebitelském úvěru, v platném znění (dále jen jako „zákon o spotřebitelském úvěru“). Tyto subjekty jsou držitelé potřebných licencí pro zprostředkovatelskou činnost
        a jsou registrovány u České národní banky. Tedy společnost P4L s.r.o. pouze poskytuje software, který spravuje, také doménu www.p4life.cz a poskytuje odběratelům marketingové řešení na míru.
        <br />   <br />1.3 Prohlášení k závaznosti informací – není–li výslovně uvedeno jinak, modelové příklady, kalkulace a výpočty ve všech kalkulačkách na tomto webu jsou orientační a nejsou nabídkou k uzavření smlouvy, příslibem k uzavření smlouvy, vyhlášením veřejné soutěže o nejvýhodnější nabídku, veřejnou nabídku ani jakýmkoliv jiným právním jednáním P4L s.r.o., a to bez ohledu na jeho obsah či označení; pokud kterákoliv část tohoto webu vykazuje obsahové
        náležitosti jiného právního jednání P4L s.r.o., je výhradně právně nezávazným informativním sdělením, i pokud tak není zvlášť označena.
        <br /><br />1.4 Tyto všeobecné obchodní podmínky (dále jen „VOP“) stanoví základní pravidla, kterými se budou řídit všechny vztahy vzniklé mezi Poskytovatelem a Uživatelem.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 2. Definice Pojmů</span>
        <br />2.1 „Poskytovatel“ znamená společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru provozující webové stránky www.p4life.cz pro svoje odběratele softwaru (smluvní partnery).
        <br /><br />2.2 „Uživatel“ znamená Zájemce, Makléře a jakákoliv jiná osoba využívající služby na Portálu Poskytovatele.
        <br /><br />2.3 „Portál“ znamená webové rozhraní dostupné na adrese www.p4life.cz, které umožňuje sdružovat a srovnávat mezi sebou nabídky a poptávky hypoték a zpřístupňuje kontakty jednotlivých subjektů.
        <br /><br />2.4 „Makléř“ znamená, fyzická osoba podnikající nebo zaměstnanec právnické osoby, zabývající se zprostředkováním koupě/prodeje nemovitých věcí ve prospěch Zájemce.
        <br /><br />2.5 „Poradce“ znamená fyzická osoba podnikající v rámci aktivit P4L s.r.o., IČO: 04534999, sídlo: Korunní 588/4, 120 00 Praha 2 a vybraných smluvních odběratelů, tedy makléřských a poradenských společností, se specializací na
        hypoteční úvěry (s oprávněním zprostředkovávat spotřebitelské úvěry).
        <br /> <br />2.6 „Zájemce“ znamená koncový zákazník, poptávající informace o hypotečním úvěru nebo žádající o propojení s hypotečním specialistou, jehož údaje poskytl Makléř prostřednictvím Portálu Poradcům, nebo které uvedl do Portálu sám.
        <br /> <br />2.7 „Hypotéka“ znamená hypoteční úvěr (spotřebitelský úvěr) nebo úvěr ze stavebního spoření, případně jiný finanční produkt, o kterém poskytuje bližší informace příslušný Poradce specializující se na oblast hypotečních úvěrů (spotřebitelských úvěrů).
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>  3. Účel Portálu</span>
        <br />3.1 Účelem Portálu je na straně jedné sdružovat Poradce a propojovat se Zájemci. Na straně druhé sdružovat poptávky Zájemců a Makléřů o získání bližších informací ohledně Hypotéky. Účelem Portálu je dále umožnit rychlejší získání kontaktu na osobu poskytující informace ohledně hypotečního úvěru. Veškeré informace publikované na tomto webu Poskytovatelem jsou určeny výhradně ke studijním a informativním účelům a v žádném případě je nelze považovat za investiční doporučení, nebo nabídku ve smyslu příslušných
        právních předpisů.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 4. Registrace Uživatele</span>
        <br />4.1 Zájemce osobně nebo prostřednictvím Makléře zadá do formuláře dostupného na Portálu podstatné a osobní údaje pro orientační srovnání Hypoték. Těmito údaji jsou – jméno, příjmení, e-mailová adresa, telefonní číslo, výše úvěru a hodnota nemovitosti.
        <br /><br />4.2 Pro získání výsledku srovnávače se Uživatel musí seznámit s těmito VOP. Vyjádření souhlasu prostřednictvím políčka „souhlasím s VOP“ je nezbytnou podmínkou pro užívání Portálu.
        <br /><br />4.3 Po odeslání požadavku k vyhledání Hypotéky je Uživateli zaslán na uvedenou e-mailovou adresu orientační propočet dle zadaných parametrů Zájemce.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 5. Odpovědnost poskytovatele</span>
        <br /><br />5.1 Poskytovatel neodpovídá za činnost Uživatelů v souvislosti s využíváním služeb Portálu ani za způsob jeho užívání. Poskytovatel nenese odpovědnost za případné zneužití těchto služeb Uživateli, Poradci či třetími osobami. Poskytovatel neodpovídá za žádné škody vzniklé Uživatelům či třetím osobám v důsledku užívání či naopak nemožnosti využívání služeb Portálu nebo v souvislosti s nimi. Poskytovatel neodpovídá za plnění povinností v rámci závazků vzniklých mezi Uživateli a Poradci.

        <br /><br />5.2 Uživatel bere na vědomí, že Poskytovatel objektivně nemůže zaručit stoprocentní funkčnost ani bezchybnost fungování služeb Portálu, a to s ohledem na technickou náročnost procesů, které v rámci Portálu probíhají.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>  6. Vyřizování stížností Uživatelů</span>
        <br />6.1 Vyřizování stížností Uživatelů zajišťuje Poskytovatel prostřednictvím
        elektronické adresy obchod@p4life.cz.
    </p> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>  7. Ceny a poplatky</span>
        <br />7.1 Využívání všech možností a služeb Portálu Uživatelem je zcela zdarma.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 8. Práva a povinnosti</span>
        <br />8.1 Uživatel prohlašuje že
        <br />– je-li Uživatelem Makléř, prohlašuje, že je zplnomocněn k jednání za Zájemce a byl mu poskytnut souhlas se zpracováním osobních údajů Zájemce pro účely užívání Portálu a zpracování nabídky Hypotéky,
        <br />– je plně způsobilý k právnímu jednání, a to zejména s ohledem na svůj věk,
        <br />– nebyl zbaven způsobilosti k právnímu jednání, ani mu tato nebyla omezena v rozsahu znemožňujícím mu vyslovení souhlasu s těmito VOP,
        <br />– veškeré údaje, které o sobě při registraci poskytl, jsou pravdivé, úplné, přesné a správné,
        <br />– že se seznámil s textem těchto VOP a s těmito souhlasí,
        <br />– na základě uděleného Souhlasu se zpracováním je seznámen se skutečností, že jím poskytnuté údaje může Poskytovatel předat/poskytnout Poradcům,

        <br />– pokud zjistí zneužití svých identifikačních údajů, okamžitě toto sdělí Poskytovateli.
        <br /><br />8.2 Poskytovatel je
        <br /> <br />8.3 Poskytovatel prohlašuje, že
        <br />– oprávněn na základě uděleného Souhlasu se zpracováním předat sdělené identifikační údaje Poradci,
        <br />– kdykoliv pozastavit činnost Portálu.
        <br />– není koncovým dodavatelem finančních produktů-Hypoték,
        <br />– poskytuje v rámci Portálu prostor pro získání obecných informací týkajících se tématu Hypoték dle kritérií Zájemce. Tyto informace jsou pouze orientační a slouží výhradně ke studijním účelům. Poskytovatel propojuje Uživatele pro získání bližších informací o úvěrech s Poradci.
        <br /> <br />8.4 Bližší specifikace práv a povinností Makléře a Poradce je zahrnuta ve zvlášť uzavíraných smlouvách.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 9. Ochrana osobních údajů</span>
        <br />9.1 Ochrana osobních údajů Zájemce, který je fyzickou osobou, je poskytována v souladu s pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně
        osobních údajů (dále jen „Nařízení“).
        <br /><br />9.2 Informace o zpracování osobních údajů společností P4L v souladu
        s Nařízením jsou dostupné na webové stránce Poskytovatele www.p4life.cz.
    </p> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>  10. Závěrečná ustanovení </span>
        <br />10.1 Komunikace mezi Poskytovatelem a Uživateli, Poradci a případnými třetími osobami probíhá výhradně prostřednictvím emailu, na emailové adrese Poskytovatele obchod@p4life.cz.
        <br /><br />10.2 Právem rozhodným pro veškeré případné spory vzniklé z tohoto smluvního vztahu je právní řád České republiky.

        <br /><br />10.3 Všechny spory vznikající z této smlouvy a v souvislosti s ní budou rozhodovány s konečnou platností u Rozhodčího soudu při Hospodářské komoře České republiky a Agrární komoře České republiky podle jeho řádu jedním rozhodcem jmenovaným předsedou Rozhodčího soudu.
        <br /><br />10.4 Je-li některé ustanovení VOP neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení. Změny
        a doplňky VOP vyžadují písemnou formu, přičemž o jejich změně bude objednatel informován prostřednictvím oznámení na Portálu.
        Tyto VOP nabývají platnosti a účinnosti dne 25. 5. 2018.
    </p>
</>;

const LegalContantTermsAndServicesEN = () => <>
    <p className="text-xs sm:text-sm text-gray-500 ">
        <span className='text-base font-semibold text-gray-700'> 1. Introduction</span><br />
        1.1 The company P4L s.r.o., IČ 04534999, with its registered office at Korunní 588/4, 120 00 Prague 2, registered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 249264, is a provider of software, IT solutions and marketing (registered trademarks) and operates the website www.p4life.cz to its contractual partners (customers, contractual advisors), provides services under the conditions listed below. The website www.p4life.cz and related services serve to mediate business opportunities (contacts), inquiries and offers and make contacts available in order to establish cooperation for the User. Inquiry means a sent structured request from the user in order to obtain general information regarding a mortgage loan (hereinafter referred to as “mortgage”) through a connection (both by telephone and in person) with the Adviser.<br />
        <br /> 1.2 P4L s.r.o. provides software and registered trademark and brand marketing services. These services are provided to customers (contractual partners). In the same way, the entire website is operated on the basis of a contractually given relationship (or paid licenses) for customers. These customers (contractual partners) are companies or individual persons doing business in the field of financial consulting with the right to mediate consumer loans (mortgages) according to Act No. 257/2016 Coll. on Consumer Credit, as amended (hereinafter referred to as the “Consumer Credit Act”). These entities hold the necessary licenses for brokerage activities and are registered with the Czech National Bank. Thus, the company P4L s.r.o. it only provides the software it manages, also the domain www.p4life.cz and provides customers with a customized marketing solution.
        <br />   <br />1.3 Declaration of Binding Information - Unless expressly stated otherwise, model examples, calculations and calculations in all calculators on this site are indicative and do not constitute an offer to enter into a contract, a promise to enter into a contract, a call for tenders, a public offer or any other legal action of P4L s.r.o., regardless of its content or designation; if any part of this website shows the content of another legal act of P4L s.r.o., it is exclusively a legally non-binding informative communication, even if it is not specifically marked as such.
        <br /><br />1.4 These General Terms and Conditions (hereinafter referred to as the "GTC") set out the basic rules that will govern all relations between the Provider and the User.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>2. Definitions of Terms</span>
        <br />2.1 “Provider” means the company P4L s.r.o., IČ 04534999, with its registered office at Korunní 588/4, 120 00 Prague 2, registered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 249264, is a software provider operating the website www.p4life. cz for its software customers (contractual partners).
        <br /><br />2.2 "User" means the Applicant, Broker and any other person using the services on the Provider's Portal.
        <br /><br />2.3 "Portal" means the web interface available at www.p4life.cz, which allows you to group and compare mortgage offers and demands and makes available the contacts of individual entities.
        <br /><br />2.4 “Broker” means a natural person doing business or an employee of a legal entity engaged in arranging the purchase / sale of real estate for the benefit of the Applicant.
        <br /><br />2.5 “Advisor” means a natural person doing business within the activities of P4L s.r.o., IČO: 04534999, registered office: Korunní 588/4, 120 00 Prague 2 and selected contractual customers, ie brokerage and consulting companies, specializing in mortgage loans (authorized to mediate consumer loans).
       
        <br /> <br />2.6 “Applicant” means an end customer requesting information about a mortgage loan or requesting a connection with a mortgage specialist, whose data were provided by the Broker through the Portal to the Advisors, or which he entered into the Portal himself.
        <br /> <br />2.7 “Mortgage” means a mortgage loan (consumer loan) or a building savings loan, or another financial product, about which more detailed information is provided by the relevant Advisor specializing in the field of mortgage loans (consumer loans).
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 3. Purpose of the Portal</span>
        <br />3.1 The purpose of the Portal is, on the one hand, to associate Advisers and to connect with Interested Parties. On the other hand, to combine the demands of Interested Parties and Brokers to obtain more detailed information about the Mortgage. The purpose of the Portal is also to enable faster contact with a person providing information about a mortgage loan. All information published on this website by the Provider is intended solely for study and informational purposes and in no case can it be considered an investment recommendation or an offer in the sense of the relevant legal regulations.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 4. User Registration</span>
        <br />4.1 The Applicant shall personally or through the Broker enter in the form available on the Portal essential and personal data for an indicative comparison of Mortgages. These data are - name, surname, e-mail address, telephone number, loan amount and property value.
        <br /><br />4.2 In order to obtain the result of the comparator, the User must become acquainted with these GTC. Expressing consent through the field "I agree with the GTC" is a necessary condition for using the Portal.
        <br /><br />4.3 After sending the request to find the Mortgage, the User is sent to the specified e-mail address an approximate calculation according to the specified parameters of the Applicant.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 5. Liability of the provider</span>
        <br /><br />5.1 The Provider is not responsible for the Users' activities in connection with the use of the Portal's services or for the manner of its use. The Provider is not responsible for any misuse of these services by the User, the Advisor or third parties. The Provider is not liable for any damages incurred by Users or third parties as a result of the use or, conversely, the impossibility of using the Portal services or in connection with them. The Provider is not responsible for fulfilling the obligations within the obligations arising between the Users and the Advisers.

        <br /><br />5.2 The User acknowledges that the Provider cannot objectively guarantee the 100% functionality or faultless operation of the Portal's services, with regard to the technical complexity of the processes that take place within the Portal.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>  6. Handling of User Complaints</span>
        <br />6.1 The handling of Users' complaints is provided by the Provider via the electronic address obchod@p4life.cz.
    </p> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>7. Prices and fees</span>
        <br />7.1 The use of all possibilities and services of the Portal by the User is completely free of charge.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'>8. Rights and obligations</span>
        <br />8.1 The user declares that
        <br />– if the User is a Broker, declares that he is authorized to act on behalf of the Applicant and has been given consent to the processing of the Applicant's personal data for the purposes of using the Portal and processing the Mortgage offer,
        <br />– is fully competent to act, in particular with regard to his age,
        <br />– has not been deprived of legal capacity, nor has it been restricted to the extent that it is impossible for him to give his consent to these GTC,
        <br />– all the information he provided on his registration is true, complete, accurate and correct,
        <br />– that he has read the text of these GTC and agrees with them,
        <br />– on the basis of the Consent to Processing granted, he is acquainted with the fact that the Provider may pass on / provide the data provided by him to the Consultants,

        <br />– if it finds out the misuse of its identification data, it shall immediately notify the Provider thereof.
        <br /><br />8.2 The Provider is
        <br /> <br />8.3 The Provider declares that
        <br />– is entitled to submit the communicated identification data to the Consultant on the basis of the granted Consent to Processing,
        <br />– suspend the operation of the Portal at any time.
        <br />– is not an end supplier of financial products-Mortgages,
        <br />– provides space within the Portal to obtain general information related to the topic of Mortgages according to the criteria of the Applicant. This information is for guidance only and is for study purposes only. The Provider connects the User with the Advisers for more detailed information on loans.
        <br /> <br />8.4 A more detailed specification of the rights and obligations of the Broker and the Advisor is included in separately concluded contracts.
    </p> <br /> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 9. Protection of personal data</span>
        <br />9.1 Protection of personal data The candidate who is a natural person is provided in accordance with the rules set out in Regulation (EU) No. 2016/679 of the European Parliament and of the Council of 27 April 2016, the General Regulation on Personal Data Protection (hereinafter "the Regulation"). ).
        <br /><br />9.2 Information on the processing of personal data by P4L in accordance with the Regulation is available on the Provider's website www.p4life.cz.
    </p> <br />
    <p className="text-xs sm:text-sm text-gray-500">
        <span className='text-base font-semibold text-gray-700'> 10. Final provisions</span>
        <br />10.1 Communication between the Provider and Users, Advisors and any third parties takes place exclusively via email, at the Provider's email address obchod@p4life.cz.
        <br /><br />10.2 The law applicable to all possible disputes arising from this contractual relationship is the law of the Czech Republic.

        <br /><br />10.3 All disputes arising out of and in connection with this Agreement shall be finally settled by the Arbitration Court at the Economic Chamber of the Czech Republic and the Agrarian Chamber of the Czech Republic in accordance with its rules by one arbitrator appointed by the Chairman of the Arbitration Court.
        <br /><br />10.4 If any provision of the GTC is or becomes invalid or ineffective, the invalid provision will be replaced by a provision whose meaning is as close as possible to the invalid provision. The invalidity or ineffectiveness of one provision shall not affect the validity of the other provisions. Changes and additions to the GTC require a written form, and the customer will be informed of their change through a notice on the Portal. These GTC come into force and effect on 25 May 2018.
    </p>
</>;











/*

function LegalModal(){
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    /*
    if(appUI.showLegalModal){
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
    else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  
    function renderModalTitle(){
        if(appUI.modalLegalType === 'conditions'){
          return (
            <Row className="justify-content-md-center">
            <Col md="auto"  className="header-col language-select"><h3 className="fw-light text-light"><LanguageSelect /> {t('General Terms and Conditions')}</h3></Col>
            </Row>
          )
        }
        else if(appUI.modalLegalType === 'private'){
          return (
            <Row className="justify-content-md-center">
            <Col md="auto" className="header-col"><h3 className="fw-light text-light"> {t('Information on the processing of personal data')}</h3></Col>
            </Row>
          )
        }
      }
      
   
    
      function renderModalBody(){
        if(appUI.modalLegalType === 'conditions'){
          return <></>;
        }
        else if(appUI.modalLegalType === 'private'){
          return <div className="legal-headers legal-lists" dangerouslySetInnerHTML={{__html: t("Private Policy All")}}></div>;
        }
      }
    
      function handleCloseButtonClick(){
        dispatch({type: SET_SHOW_MODAL_LEGAL, payload: {show: false, legalType: ''}})
      
      }
      let fullscreen= true;
        return(
            <>
             <Modal
            show={appUI.showLegalModal}
            className="modal-fullscreen-custom "
          >
              
            <Modal.Header  className="text-center bg-dark text-light" > 
            <Col md="2" className="d-flex justify-content-start  language-select">
            <Button
                            onClick={() =>  handleCloseButtonClick()}
                            variant="link"
                            className="dark-a "
                    ><BsX size={25} color="#fff"/>
                    </Button>
                    <LanguageSelect />
            </Col>
                <Col  className="text-center">
                {renderModalTitle()}
                </Col>
                <Col md="2">
              
                </Col>
            </Modal.Header>
            <Modal.Body className="legal-body bg-dark text-light">
                {renderModalBody()}
            </Modal.Body>
            
          </Modal>
        </>
    )
}


*/
