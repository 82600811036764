import React, {useState, useEffect} from 'react';
import { animateScroll as scroll } from 'react-scroll'
import { ChevronUpIcon } from '@heroicons/react/outline'

function ScrollTop(){
    const [show, setShow] = useState(false);

    useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    
    function handleScroll() {
    setShow(window.pageYOffset > 160);
    };

    function scrollToTop() {
        scroll.scrollToTop();
    }

    if(show)
    return(
        <>
          <button
        type="button"
        onClick={scrollToTop}
        className="fixed bottom-9 right-8  items-center p-5 border border-transparent rounded-full shadow-sm text-white  bg-gradient-to-r from-blue-700 to-blue-800 hover:from-blue-800 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
      >
        <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
      </button>
        </>
    )

    return null;
}

export default ScrollTop;