import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useDispatch} from 'react-redux';
import LanguageSelect from '../LanguageSelect';
import { Helmet } from 'react-helmet-async';
import { SET_SHOW_MODAL_LEGAL } from '../../actions/types';
import LegalSlideover from '../LegalSlideOver';

function NotFoundPage() {
    const { t } = useTranslation();
    const dispatch= useDispatch();
    return (
        <>
            <Helmet>
                <title>404 | P4L-GROUP</title>
                <meta name="description" content="{t('notFound.error404')} {t('notFound.title')} " />
            </Helmet>
            <div className="bg-white min-h-screen flex flex-col lg:relative ">
                <div className="flex-grow flex flex-col">
                    <main className="flex-grow flex flex-col bg-white">
                        <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
                            <div className="flex-shrink-0 pt-10 sm:pt-16">
                                <Link to="/" className="inline-flex">
                                    <span className="sr-only">P4L-GROUP</span>
                                    <img
                                        className="h-12 w-auto md:h-16 lg:h-20"
                                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP2.png"
                                        alt="P4L-GROUP Logo"
                                    />
                                </Link>
                            </div>
                            <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                                <p className="text-sm font-semibold text-blue-700 uppercase tracking-wide">  {t('notFound.error404')}</p>
                                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    {t('notFound.title')}
                                </h1>
                                <p className="mt-2 text-base text-gray-500">{t('notFound.description')}</p>
                                <div className="mt-6">
                                    <Link to="/" className="text-base font-medium text-blue-700 hover:text-blue-800">
                                        {t('notFound.returnHome')}<span aria-hidden="true"> &rarr;</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className="flex-shrink-0 bg-gray-50">
                        <div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
                            <nav className="flex space-x-4">
                                <Link to="/contact" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                                    {t('landing.header.contact')}
                                </Link>
                                <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                                <span
                                    onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'private' } })}
                                    className="text-sm font-medium text-gray-500 hover:text-gray-600 hover:cursor-pointer">
                                    {t('common.conditions')}
                                </span>
                                <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                                <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                                    {t('common.home')}
                                </Link>
                                <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                                <LanguageSelect />
                            </nav>
                        </div>
                    </footer>
                </div>
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
                        alt=""
                    />
                </div>
            </div>
            <LegalSlideover />
        </>
    )
}

export default NotFoundPage;