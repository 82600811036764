import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SET_LANGUAGE } from '../actions/types';

function LanguageSelect() {

    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);

    function setLanguage(language) {
        dispatch({ type: SET_LANGUAGE, payload: language })
    }
    const { i18n } = useTranslation();
    useEffect(() => {
        const lanfuagePreference = localStorage.getItem('i18nextLng');
        if (lanfuagePreference) {
            if (lanfuagePreference === 'cz') {

                setLanguage('en');
            }
            else {
                setLanguage('cz');
            }

        }
    }, [])

    function handleLanguageChange() {
        if (appUI.language === 'en') {
            i18n.changeLanguage('en');
            localStorage.setItem('i18nextLng', 'en');
            setLanguage('cz');
        }
        else {

            i18n.changeLanguage('cz');
            localStorage.setItem('i18nextLng', 'cz');
            setLanguage('en');
        }
    }
    return (
        <>
            <a className="hover:cursor-pointer" onClick={() => handleLanguageChange()}>{appUI.language === 'cz' ?
                <img
                    alt="Czech Language"
                    className="w-8 h-auto drop-shadow-xl "
                    src="https://purecatamphetamine.github.io/country-flag-icons/3x2/CZ.svg" /> :
                <img
                    alt="English Language"
                    className="w-8 h-auto drop-shadow-xl"
                    src="https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />}
            </a>
        </>
    )
}

export default LanguageSelect;