import React from 'react';
import Footer from '../Footer';
import Header from './Header';
import ScrollTop from '../ScrollTop';
import { animateScroll as scroll } from 'react-scroll'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_MODAL_LEGAL } from '../../actions/types';
import ContactForm from './ContactForm';
import ContactSubmited from './ContactSubmited';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ContactPage() {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const { t } = useTranslation();

    const offices = [
       // { id: 1, city: 'Praha - budova Base', address: ['Italská 2581/67,', '120 00 Praha'] },
        { id: 2, city: 'Palác JBX', address: ['Palackého 3145/41', '466 01, Jablonec nad Nisou'] },
        { id: 3, city: t('contact.anywhere'), address: [] },
    
    ]
    
    function renderForm(){
        if (appUI.submittingMainContactData) {
            return (
                <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-72 sm:px-6 lg:px-8">
    
                    <div className="mx-auto flex items-center justify-center  rounded-full mb-5">
                        <svg class="animate-spin h-16 w-16 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    <h2 className="text-3xl font-extrabold text-gray-800 sm:text-4xl">{t('contact.form.submitting')}</h2>
                   
    
                </div>
            )
        }
        else if(appUI.submitContactMessage !== ''){
            return <ContactSubmited />;
        }
        return <ContactForm />;
    }
    useEffect(() => {
        scroll.scrollToTop();
    }, [])
    return (
        <>
            <Helmet>
                <title>{t('landing.header.contact')} | P4L-GROUP</title>
            </Helmet>
            <div className="bg-white">
                <Header />

                <main className="overflow-hidden">
                    {/* Header */}
                    <div className="bg-warm-gray-50">
                        <div className="py-24 lg:py-32">
                            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                                <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                                    {t('contact.title')}
                                </h1>
                                <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                                    {t('contact.subTitle')}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Contact section */}
                    <section className="relative bg-white" aria-labelledby="contact-heading">
                        <div className="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
                        {/* Decorative dot pattern */}
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <svg
                                className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-warm-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                            </svg>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="relative bg-white shadow-xl">
                                <h2 id="contact-heading" className="sr-only">
                                    Contact us
                                </h2>

                                <div className="grid grid-cols-1 lg:grid-cols-3">
                                    {/* Contact information */}
                                    <div className="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-blue-500 to-blue-600 sm:px-10 xl:p-12">
                                        {/* Decorative angle backgrounds */}
                                        <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                            <svg
                                                className="absolute inset-0 w-full h-full"
                                                width={343}
                                                height={388}
                                                viewBox="0 0 343 388"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                                    fill="url(#linear1)"
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear1"
                                                        x1="254.553"
                                                        y1="107.554"
                                                        x2="961.66"
                                                        y2="814.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div
                                            className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="absolute inset-0 w-full h-full"
                                                width={359}
                                                height={339}
                                                viewBox="0 0 359 339"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                                    fill="url(#linear2)"
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear2"
                                                        x1="192.553"
                                                        y1="28.553"
                                                        x2="899.66"
                                                        y2="735.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div
                                            className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="absolute inset-0 w-full h-full"
                                                width={160}
                                                height={678}
                                                viewBox="0 0 160 678"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                                    fill="url(#linear3)"
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear3"
                                                        x1="192.553"
                                                        y1="325.553"
                                                        x2="899.66"
                                                        y2="1032.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <h3 className="text-lg font-medium text-white">  {t('contact.form.infoTitle')}</h3>
                                        <p className="mt-6 text-base text-blue-50 max-w-3xl">
                                            {t('contact.form.infoSubTitle')}
                                        </p>
                                        <dl className="mt-8 space-y-6">
                                            <dt>
                                                <span className="sr-only">Phone number</span>
                                            </dt>
                                            <dd className="flex text-base text-blue-50">
                                                <PhoneIcon className="flex-shrink-0 w-6 h-6 text-blue-200" aria-hidden="true" />
                                                <a href="tel:+420 732 265 505" className="ml-3">+420 732 265 505</a>
                                            </dd>
                                            <dt>
                                                <span className="sr-only">Email</span>
                                            </dt>
                                            <dd className="flex text-base text-blue-50">
                                                <MailIcon className="flex-shrink-0 w-6 h-6 text-blue-200" aria-hidden="true" />
                                                <a href="mailto:obchod@p4life.cz" className="ml-3">obchod@p4life.cz</a>
                                            </dd>
                                        </dl>
                                        {/*
                                          <ul role="list" className="mt-8 flex space-x-12">
                                            <li>
                                                <a className="text-blue-200 hover:text-blue-100" href="#">
                                                    <span className="sr-only">Facebook</span>
                                                    <svg className="w-7 h-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="text-blue-200 hover:text-blue-100" href="#">
                                                    <span className="sr-only">GitHub</span>
                                                    <svg className="w-7 h-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="text-blue-200 hover:text-blue-100" href="#">
                                                    <span className="sr-only">Twitter</span>
                                                    <svg className="w-7 h-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>F
                                        */}
                                      
                                    </div>

                                    {/* Contact form */}
                                    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                       {renderForm()}
                                     
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Contact grid */}
                    <section aria-labelledby="offices-heading ">
                        <div className="max-w-7xl mx-auto py-24 px-4 sm:pt-32 sm:pb-22 sm:px-6 lg:px-8 ">
                            <h2 id="offices-heading" className="text-3xl font-extrabold text-warm-gray-900">
                                {t('contact.title2')}
                            </h2>
                            <p className="mt-6 text-lg text-warm-gray-500 max-w-3xl">
                                {t('contact.subtitle2')}
                            </p>
                            <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
                                {offices.map((office) => (
                                    <div key={office.id}>
                                        <h3 className="text-lg font-medium text-warm-gray-900">{office.city}</h3>
                                        <p className="mt-2 text-base text-warm-gray-500">
                                            {office.address.length ? 
                                            office.address.map((line) => (
                                                <span key={line} className="block">
                                                    {line}
                                                </span>
                                            )):
                                            null
                                        }
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    {/* Newsletter */}
                    {/*
                     <div className="relative mb-12">
                        <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="py-10 px-6 bg-gradient-to-l from-blue-800 to-blue-500 rounded-3xl sm:py-16 sm:px-12 lg:py-20 lg:px-20 lg:flex lg:items-center">
                                <div className="lg:w-0 lg:flex-1">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-white"> {t('contact.newsletter.title')}</h2>
                                    <p className="mt-4 max-w-3xl text-lg text-cyan-100">
                                        {t('footer.subscribeToNewsDescription')}
                                    </p>
                                </div>
                                <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
                                    <form className="sm:flex">
                                        <label htmlFor="email-address" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="email-address"
                                            name="email-address"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="w-full border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none  rounded-md"
                                            placeholder={t('footer.subscribeToNewsPlaceholder')}
                                        />
                                        <button
                                            type="submit"
                                            className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-indigo-50 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                                        >
                                            {t('footer.subscribe')}
                                        </button>
                                    </form>
                                    <p className="mt-3 text-sm text-cyan-100" >
                                        {t('contact.newsletter.personalData1')} <br />
                                        <span className="inline-flex">
                                            {t('contact.newsletter.personalData2')}{' '} &nbsp;
                                            <p className="text-white font-medium underline hover:cursor-pointer"   onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'private' } })}>
                                                {t('contact.newsletter.personalData3')}
                                            </p>
                                        </span>

                                    </p>
                                </div>
                            </div>
                        </div>
                        </div
                    */}
                   
                </main>
                <Footer />
            </div>
            <ScrollTop />
        </>
    )
}