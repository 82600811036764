import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SUBMIT_CONTACT_MESSAGE } from '../../actions/types';
import { useTranslation } from 'react-i18next';
import { CheckIcon, XIcon } from '@heroicons/react/outline';


function ContactSubmited() {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const { t } = useTranslation();

  
    if (appUI.submitContactMessage === 'success') {
        return (
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-52 sm:px-6 lg:px-8">

                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full mb-5 bg-green-100">
                    <CheckIcon className="h-10 w-10 text-green-600" aria-hidden="true" />
                </div>
                <h2 className="text-3xl font-extrabold text-gray-800 sm:text-4xl">{t('Thank you for submitting your message.')}</h2>
                <p className="mt-4 text-lg leading-6 text-gray-600">
                    {t('We will contact you soon.')}
                </p>



                <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-green-50 hover:bg-green-100 sm:w-auto"
                    onClick={() => {
                        dispatch({ type: SET_SUBMIT_CONTACT_MESSAGE, payload: '' });
                    }}
                >{t('I understand')}</button>


            </div>
        )

    }
    else if (appUI.submitContactMessage === 'error') {
        return (
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-52 sm:px-6 lg:px-8">

                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full mb-5 bg-red-100">
                    <XIcon className="h-10 w-10 text-red-600" aria-hidden="true" />
                </div>
                <h2 className="text-3xl font-extrabold text-gray-800 sm:text-4xl">{t('Thank you for submitting your message.')}</h2>
                <p className="mt-4 text-lg leading-6 text-red-600">
                    {t('Unfortunately')}
                </p>
                <p className="mt-2 text-base leading-6 text-gray-600">
                    {t('We will ask you')}
                </p>
                <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-red-50 hover:bg-red-100 sm:w-auto"
                    onClick={() => {
                        dispatch({ type: SET_SUBMIT_CONTACT_MESSAGE, payload: '' });
                    }}
                >{t('I understand')}</button>

            </div>
        )
    }
    else return null;
}

export default ContactSubmited;