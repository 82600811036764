import { useTranslation } from 'react-i18next';

export default function Leadership() {
    const { t } = useTranslation();
    const people = [
        {
            name: 'Lucie Berková',
            role: t('common.chiefFinancilOfficer'),
            imageUrl:
                'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/Berkova.jpg',
            company: 'Finance'
        },
        
        {
            name: 'Lukáš Berka',
            role: t('common.chiefSalesOfficer'),
            imageUrl:
                'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/Berka.jpg',
            company: t('landing.leaderShipSection.investmentDescription')
        },
        {
            name: 'Jakub Hanuš',
            role: t('common.chiefSalesOfficer'),
            imageUrl:
                'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/Hanus.jpg',
            company: t('landing.leaderShipSection.realEstateDescription')
        },
        {
            name: 'Martin Kohout',
            role: t('common.chiefTechnologyOfficer'),
            imageUrl:
                'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/Kohout2.jpg',
            company: 'Clever Solutions Technology'
        },
    ]
    return (
        <div className="relative bg-gray-50">
            <div className="absolute inset-0">
                <div className="bg-white h-1/6 md:h-1/3  xl:h-1/6" />
            </div>
            <div className="relative mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0" id="leadership">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">  {t('landing.leaderShipSection.title')}</h2>
                        <p className="text-xl text-gray-500">
                            {t('landing.leaderShipSection.description')}
                        </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul
                            role="list"
                            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
                        >
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="space-y-4">
                                        <div className="aspect-w-2 aspect-h-3 ">
                                            <img className="object-contain shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                                        </div>

                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>{person.name}</h3>
                                                <p className="text-blue-700">{person.company}</p>
                                            </div>
                                            <div className="text-lg">
                                                <p className="text-gray-500">{person.bio}</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">{person.role}</p>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}