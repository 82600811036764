import Header from './Header';
import { useTranslation } from 'react-i18next';
import {
  CashIcon,
  CodeIcon,
  HomeIcon,
  OfficeBuildingIcon,
  GlobeIcon
} from '@heroicons/react/outline'
import FixedHeader from './FixedHeader';
import ScrollTop from '../ScrollTop';
import { Link as LinkScroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Leadership from './Leadership';
import Footer from '../Footer';
import Blog from './Blog';
import { Helmet } from 'react-helmet-async';
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Testimonials } from './Testimonials';
import OurValues from './OurValues';



function LandingPage() {
  const { t } = useTranslation();
  const metrics = [
    { id: 1, stat: '8K+', emphasis: t('landing.results.stat1'), rest: 'use laoreet amet lacus nibh integer quis.' },
    { id: 2, stat: '12', emphasis: t('landing.results.stat2'), rest: 'lacus nibh integer quis.' },
    { id: 3, stat: '93%', emphasis: t('landing.results.stat3'), rest: 'laoreet amet lacus nibh integer quis.' },
    { id: 4, stat: '1.2M+', emphasis: t('landing.results.stat4'), rest: 'lacus nibh integer quis.' },
  ]
  return (
    <>
      <Helmet>
        <title>{t('common.home')} | P4L-GROUP</title>
      </Helmet>
      <div className="bg-white">
        <Header />
        <FixedHeader />
        <main>
          {/* Hero section */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/Hero.jpg"
                    alt="Hero business"
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-500 mix-blend-multiply" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 className="text-center text-3xl font-extrabold tracking-tight  lg:text-3xl">
                    <span className="block text-white">{t('landing.hero.title1')}</span>
                    <span className="block text-blue-200">{t('landing.hero.title2')}</span>
                  </h1>
                  <p className="mt-6 max-w-lg mx-auto text-center text-xl text-blue-50 sm:max-w-3xl">
                    {t('landing.hero.description')}
                  </p>
                  <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div className="flex items-center justify-center ">
                      <LinkScroll to="group-logos" smooth={true} offset={-100}
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-indigo-50 hover:cursor-pointer sm:px-8"
                      >
                        {t('landing.hero.learnMore')}
                      </LinkScroll>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Group Members Logo Cloud */}
          <div className="bg-gray-100" >
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8" >
              <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide" id="group-logos">
                {t('landing.hero.groupMembers')}
              </p>
              <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-8" >
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-2">
                  <a href="https://www.bydlimesklidem.cz/" target="_blank" >
                    <img className="h-16 lg:h-20" src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/Bydlime_s_klidem_logo_web.png" alt="Bydlíme s klidem" />
                  </a>
                </div>
                
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-2">
                  <a href="https://p4life.cz/" target="_blank" >
                    <img className="h-16 lg:h-20" src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP_Finance.png" alt="P4L Finances" />
                  </a>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-2">
                  <a href="https://lifeinspain.eu/" target="_blank" >
                    <img className="h-16 lg:h-20" src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/SpainRealestateLogoNoBackground.png" alt="Life in Spain" />
                  </a>
                </div>
                {
                  /*
                    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-2">
                  <a href="https://www.zeus-capital.cz/" target="_blank" >
                    <img
                      className="h-16 lg:h-20"
                      src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP_Investments.png"
                      alt="P4L Investments"
                    />
                  </a>
                </div>
                  */
                }
              
                <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-3 lg:col-span-2">
                  <a href="https://www.clever-technology.com/" target="_blank" >
                    <img
                      className="h-16 lg:h-20"
                      src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoClever.png"
                      alt="Clever Solutions Technology"
                    />
                  </a>
                </div>

              </div>
            </div>
          </div>

          {/* Group Members About*/}
          <div className="relative pt-16  overflow-hidden">

            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
             {/*Bydlime */}
            <div className="my-12">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" id="realEstate">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div>
                      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800">
                        <HomeIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {t('landing.header.P4LRealEstate')}
                      </h2>
                      <p className="mt-4 text-gray-500">
                        {t('landing.groupsSection.P4LRealEstateFullDescription')}
                      </p>
                      <div className="mt-6">
                        <a
                          href="https://www.bydlimesklidem.cz/" target="_blank"
                          className="inline-flex bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-600 hover:to-blue-900"
                        >
                          {t('landing.groupsSection.visitPage')}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;{t('landing.groupsSection.HanusQuote')}&rdquo;
                        </p>
                      </div>
                      <footer className="mt-3 ">
                        <LinkScroll to="leadership" smooth={true} offset={-100} className="hover:cursor-pointer hover:opacity-90">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-12 w-12 rounded-full"
                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/HanusQuote.jpg"
                                alt="Berka Quote Img"
                              />
                            </div>
                            <div className="text-base font-medium text-gray-700">
                              Jakub Hanuš<br /> <span className="text-gray-500">{t('common.chiefSalesOfficer')}</span>
                            </div>
                          </div>
                        </LinkScroll>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <a
                      href="https://www.bydlimesklidem.cz/" target="_blank"
                      className="opacity-90 hover:opacity-100"
                    >
                      <img
                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/P4LRK_site_screenshot.png"
                        alt="Real Estate Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*Finance */}
            <div className="relative">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" id="finance">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                  <div>
                    <div>
                      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800">
                        <CashIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900" >
                        {t('landing.header.P4LFinance')}
                      </h2>
                      <p className="mt-4  text-gray-500">
                        {t('landing.groupsSection.P4LFinanceFullDescription')}
                      </p>
                      <div className="mt-6">
                        <a
                          href="https://p4life.cz/" target="_blank"
                          className="inline-flex bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-600 hover:to-blue-900"
                        >
                          {t('landing.groupsSection.visitPage')}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;{t('landing.groupsSection.BerkovaQuote')}&rdquo;
                        </p>
                      </div>
                      <footer className="mt-3 ">
                        <LinkScroll to="leadership" smooth={true} offset={-100} className="hover:cursor-pointer hover:opacity-90">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-12 w-12 rounded-full"
                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/BerkovaQuote.jpg"
                                alt="Berka Quote Img"
                              />
                            </div>
                            <div className="text-base font-medium text-gray-700">
                              Lucie Berková<br /> <span className="text-gray-500">{t('common.chiefFinancilOfficer')}</span>
                            </div>
                          </div>
                        </LinkScroll>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <a
                      href="https://p4life.cz/" target="_blank"
                      className="opacity-90 hover:opacity-100"
                    >
                      <img
                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/P4LIFE_site_screenshot.png"
                        alt="Finance Image"
                      />
                    </a>
                  </div>


                </div>
              </div>
            </div>
            {/*Life in Spain */}
            <div className="mt-12">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" id="lifeInSpain">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div>
                      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800">
                        <GlobeIcon className="h-6 w-6 text-white" aria-hidden="true" />

                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {t('landing.header.LifeInSpain')}

                      </h2>
                      <p className="mt-4 text-gray-500">
                        {t('landing.groupsSection.LifeInSpainFullDescription')}

                      </p>
                      <div className="mt-6">
                        <a
                          href="https://www.lifeinspain.eu" target="_blank"
                          className="inline-flex bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-600 hover:to-blue-900"
                        >
                          {t('landing.groupsSection.visitPage')}
                        </a>


                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;{t('landing.groupsSection.LifeInSpainQuote')}&rdquo;

                        </p>
                      </div>
                      <footer className="mt-3 ">
                        <div  className=" hover:opacity-90">
                          <div className="flex items-center space-x-3">

                            <div className="flex-shrink-0">
                              <img
                                className="h-20 w-20 rounded-full"
                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/SpainRealestateLogoSmall.jpg"
                                alt="Life in Spain Quote Img"
                              />
                            </div>
                            <div className="text-base font-medium text-gray-700">
                            {t('landing.groupsSection.YourTeam')}<br /> <span className="text-gray-500">{t('landing.header.LifeInSpain')}</span>

                            </div>
                          </div>

                        </div>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <a
                      href="https://lifeinspain.eu" target="_blank"
                      className="opacity-90 hover:opacity-100"
                    >
                      <img
                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/LifeInSpain_site_screenshot.png"
                        alt="Software Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*Development */}
            <div className="relative mt-12">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" id="investment" >
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                  <div>
                    <div>
                      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800">
                        <OfficeBuildingIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {t('landing.header.P4LInvestment')}
                      </h2>
                      <p className="mt-4  text-gray-500">
                        {t('landing.groupsSection.P4LInvestmentFullDescription')}
                      </p>
                      <div className="mt-6">
                        <Link
                          to="/contact"
                          className="inline-flex bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-600 hover:to-blue-900"
                        >
                          {t('landing.groupsSection.interestedInInvestments')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;{t('landing.groupsSection.BerkaQuote')}&rdquo;
                        </p>
                      </div>
                      <footer className="mt-3 ">
                        <LinkScroll to="leadership" smooth={true} offset={-100} className="hover:cursor-pointer hover:opacity-90">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-12 w-12 rounded-full"
                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/BerkaQuote.jpg"
                                alt=""
                              />
                            </div>
                            <div className="text-base font-medium text-gray-700">
                              Lukáš Berka<br /> <span className="text-gray-500">{t('common.chiefSalesOfficer')}</span>
                            </div>
                          </div>
                        </LinkScroll>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <Link
                       to="/contact"
                      className="opacity-90 hover:opacity-100"
                    >
                      <img
                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/Development_new.jpg"
                        alt="Investments Image"
                      />
                     </Link>
                  </div>
                </div>
              </div>
            </div>
            {/*CST */}
            <div className="mt-12">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24" id="cst">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div>
                      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800">
                        <CodeIcon className="h-6 w-6 text-white" aria-hidden="true" />

                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {t('landing.header.cleverSolutionsTechnology')}

                      </h2>
                      <p className="mt-4 text-gray-500">
                        {t('landing.groupsSection.cleverSolutionsTechnologyFullDescription')}

                      </p>
                      <div className="mt-6">
                        <a
                          href="https://www.clever-technology.com/" target="_blank"
                          className="inline-flex bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-600 hover:to-blue-900"
                        >
                          {t('landing.groupsSection.visitPage')}
                        </a>


                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;{t('landing.groupsSection.KohoutQuote')}&rdquo;

                        </p>
                      </div>
                      <footer className="mt-3 ">
                        <LinkScroll to="leadership" smooth={true} offset={-100} className="hover:cursor-pointer hover:opacity-90">
                          <div className="flex items-center space-x-3">

                            <div className="flex-shrink-0">
                              <img
                                className="h-12 w-12 rounded-full"
                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/leadership/KohoutQuote.jpg"
                                alt="Berka Quote Img"
                              />
                            </div>
                            <div className="text-base font-medium text-gray-700">
                              Martin Kohout<br /> <span className="text-gray-500">{t('common.chiefTechnologyOfficer')}</span>

                            </div>
                          </div>

                        </LinkScroll>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <a
                      href="https://www.clever-technology.com/" target="_blank"
                      className="opacity-90 hover:opacity-100"
                    >
                      <img
                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/CST_site_screenshot.png"
                        alt="Software Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Blog Section */}
          {/*<Blog /> */}
          {/* Leadership section */}
          <Leadership />
          {/* Statistics Section */}
          {/*
          <div className="relative bg-gray-900">
            <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
              <div className="h-full w-full xl:grid xl:grid-cols-2">
                <div className="h-full xl:relative xl:col-start-2">
                  <img
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                    alt="People working on laptops"
                  />
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                  />
                </div>
              </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
              <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24" id="results">
                <h2 className="text-sm font-semibold text-indigo-300 tracking-wide uppercase">  {t('landing.results.smallTitle')}</h2>
                <p className="mt-3 text-3xl font-extrabold text-white">
                  {t('landing.results.title')}
                </p>
                <p className="mt-5 text-lg text-gray-300">
                  {t('landing.results.description')}
                </p>
                <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                  {metrics.map((item) => (
                    <p key={item.id}>
                      <span className="block text-2xl font-bold text-white">{item.stat}</span>
                      <span className="mt-1 block text-base text-gray-300">
                        <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          */}
          {/* Our Values Section */}
          <OurValues />
          {/* Testimonial Section */}
          {
            /*
             <Testimonials />
            */
          }
         
          {/* CTA Section */}
          <div className="relative bg-white">
            <div className="relative">
              <div className="max-w-4xl mx-auto py-20 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">

                <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  <span className="block"> {t('landing.CTASection.wantToCooperate')}</span>
                  <span className="-mb-1 pb-1 block bg-gradient-to-r from-blue-500 to-blue-800 bg-clip-text text-transparent">
                    {t('landing.CTASection.dontHesitate')}
                  </span>
                </h2>
                <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                  <Link
                    to="/contact"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow text-blue-700 bg-gray-50 hover:bg-blue-50"
                  >
                    {t('landing.CTASection.contactUs')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
      <ScrollTop />
    </>
  )
}

export default LandingPage;