import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react'
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { SET_MAIN_CONTACT_SUBMITTING, SET_SHOW_MODAL_LEGAL, SET_SUBMIT_CONTACT_MESSAGE } from '../../actions/types';
import emailjs from 'emailjs-com';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function ContactForm() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const options = [
        { id: 1, name: t('contact.options.finance') },
        { id: 2, name: t('contact.options.realEstate') },
        { id: 3, name: t('contact.options.investment') },
        { id: 4, name: t('contact.options.software') },
    ]

    async function onSubmit(formData) {
        dispatch({ type: SET_MAIN_CONTACT_SUBMITTING, payload: true });
        const languagePreference = localStorage.getItem('i18nextLng');
        let receiver = "obchod@p4life.cz, lucie.berkova@p4life.cz"; // Finance service message receivers
        if (formData.serviceType.id === 2) { //Reality
            receiver = "obchod@p4life.cz";
        }
        else if (formData.serviceType.id === 3) { // Invesments
            receiver = "obchod@zeus-capital.cz, lukas.berka@p4life.cz";
        }
        else if (formData.serviceType.id === 4) { // Software
            receiver = "info@clever-technology.com, martin.kohout@clever-technology.com";
        }
        const contactData = {
            //Customer contact data
            customer_full_name: `${formData.firstName} ${formData.lastName}`,
            customer_first_name: formData.firstName,
            customer_last_name: formData.lastName,
            customer_email: formData.email,
            subject: formData.subject,
            phone: formData.phone,
            message: !formData.message ? 'Zákazník nevyplnil zprávu' : formData.message,
            web: window.location.origin.substring(window.location.origin.indexOf("//") + 2, window.location.origin.length),
            languagePreference,
            receiver,
        }
        //await sleep(300)
        emailjs.send('service_30xg06c', 'template_w3oxhi6', contactData, 'user_zIsu7IzIHTGx2JoId3yWZ')
            .then(function (response) {
                dispatch({ type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'success' });
                dispatch({ type: SET_MAIN_CONTACT_SUBMITTING, payload: false });
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                dispatch({ type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'error' });
                dispatch({ type: SET_MAIN_CONTACT_SUBMITTING, payload: false });
                console.log('FAILED...', error);
            });
    }
   

    return (
        <>
            <h3 className="text-lg font-medium text-warm-gray-900">{t('contact.form.title')}</h3>
            <Form
                initialValues={{
                    policyAgreed: false,
                    serviceType: { id: 1, name: t('contact.options.finance') },
                }}
                onSubmit={onSubmit}
                validate={values => {
                    const errors = {}
                    if (!values.firstName) {
                        errors.firstName = t('validation.required')
                    }
                    if (!values.lastName) {
                        errors.lastName = t('validation.required')
                    }
                    if (!values.email) {
                        errors.email = t('validation.required')
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = t('validation.invalidEmail')
                    }
                    if (!values.subject) {
                        errors.subject = t('validation.required')
                    }
                    if (!values.message) {
                        errors.message = t('validation.required')
                    } else if (values.message.length > 500) {
                        errors.message = t('validation.max500Char')
                    }
                    return errors
                }}>
                {({ handleSubmit, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-8">
                        <Field name="firstName">
                            {({ input, meta }) => (
                                <div className="sm:col-span-2">
                                    <label className="block text-sm font-medium text-warm-gray-900">
                                        {t('contact.form.firstName')}
                                    </label>
                                    <div className="mt-1">
                                        <input {...input} type="text" className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <Field name="lastName">
                            {({ input, meta }) => (
                                <div className="sm:col-span-2">
                                    <label className="block text-sm font-medium text-warm-gray-900">
                                        {t('contact.form.lastName')}
                                    </label>
                                    <div className="mt-1">
                                        <input {...input} type="text" className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <Field name="email">
                            {({ input, meta }) => (
                                <div className="sm:col-span-2">
                                    <label className="block text-sm font-medium text-warm-gray-900">
                                        {t('contact.form.email')}
                                    </label>
                                    <div className="mt-1">
                                        <input {...input} type="text" className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <Field name="phone">
                            {({ input, meta }) => (
                                <div className="sm:col-span-2">
                                    <div className="flex justify-between">
                                        <label className="block text-sm font-medium text-warm-gray-900">
                                            {t('contact.form.phone')}
                                        </label>
                                        <span id="phone-optional" className="text-sm text-warm-gray-500">
                                            {t('contact.form.optional')}
                                        </span>
                                    </div>
                                    <div className="mt-1">
                                        <input {...input} type="text" className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <Field name="serviceType">
                            {({ input, meta }) => (
                                <Listbox value={input.value} onChange={input.onChange}>
                                    {({ open }) => (
                                        <>
                                            <div className="sm:col-start-2 sm:col-span-2">
                                                <Listbox.Label className="block text-sm font-medium text-gray-700">{t('contact.options.title')}</Listbox.Label>
                                                <div className="mt-1 relative">
                                                    <Listbox.Button className="bg-white block w-full border border-gray-800 rounded-md shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:blue-indigo-500 sm:text-sm">
                                                        <span className="block truncate">{input.value.name}</span>
                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                            {options.map((option) => (
                                                                <Listbox.Option
                                                                    key={option.id}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={option}
                                                                >
                                                                    {({ active }) => (
                                                                        <>
                                                                            <span className={classNames(active ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                {option.name}
                                                                            </span>

                                                                            {active ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-blue-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            )}
                        </Field>
                        <Field name="subject">
                            {({ input, meta }) => (
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-warm-gray-900">
                                        {t('contact.form.subject')}
                                    </label>
                                    <div className="mt-1">
                                        <input {...input} type="text" className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <Field name="message">
                            {({ input, meta }) => (
                                <div className="sm:col-span-4">
                                    <div className="flex justify-between">
                                        <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                                            {t('contact.form.message')}
                                        </label>
                                        <span id="message-max" className="text-sm text-warm-gray-500">
                                            {t('contact.form.maxCharacters')}
                                        </span>
                                    </div>
                                    <div className="mt-1">
                                        <textarea {...input} type="text" rows={4} className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border border-warm-gray-300 rounded-md" />
                                        {meta.error && meta.touched && <span className='text-sm text-red-600 mt-1'>{meta.error}</span>}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <div className="sm:col-span-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <Field name="policyAgreed">
                                        {({ input, meta }) => (
                                            <Switch
                                                name="policyAgreed"
                                                {...input}
                                                checked={input.value}
                                                value={input.value}
                                                onChange={(val) => input.onChange(val)}
                                                className={classNames(
                                                    input.value ? 'bg-blue-700' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                                                )}
                                            >
                                                <span className="sr-only">Agree Policies</span>
                                                <span
                                                    className={classNames(
                                                        input.value ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                >
                                                    <span
                                                        className={classNames(
                                                            input.value ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                        )}
                                                        aria-hidden="true"
                                                    >
                                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                            <path
                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            input.value ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                        )}
                                                        aria-hidden="true"
                                                    >
                                                        <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                        </svg>
                                                    </span>
                                                </span>

                                            </Switch>
                                        )}
                                    </Field>
                                </div>
                                <div className="ml-3">
                                    <p className="text-xs sm:text-base text-gray-500">
                                        {t('contact.form.conditions1')}{' '}
                                        <span className="font-medium text-blue-600 hover:text-blue-700  underline hover:cursor-pointer"
                                            onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'private' } })}
                                        >
                                            {t('contact.form.conditions2')}
                                        </span>{' '}
                                        {t('contact.form.conditions3')}{' '}
                                        <span className="font-medium text-blue-600 hover:text-blue-700   underline hover:cursor-pointer"
                                            onClick={() => dispatch({ type: SET_SHOW_MODAL_LEGAL, payload: { show: true, legalType: 'conditions' } })}
                                        >
                                            {t('contact.form.conditions4')}
                                        </span>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 flex justify-center sm:justify-start">

                            <Field name="recaptchaResponse">
                                {({ input, meta }) => (
                                    <ReCAPTCHA
                                        sitekey='6LdiVYIgAAAAAEzfu2tm6g6j_f6VipiNZDiElYH1'
                                        onChange={input.onChange}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className="sm:col-span-2 sm:flex sm:justify-end sm:items-end">
                           
                                    <button
                                        disabled={pristine || !values.policyAgreed || !values.recaptchaResponse}
                                        className="opacity-60 enabled:opacity-100  w-full inline-flex items-center max-h-16 justify-center px-6 py-3 sm:px-12 sm:py-9 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto"
                                    >
                                        {t('contact.form.submit')}
                                    </button>
                        </div>
                    </form>
                )}
            </Form>
        </>

    );
}

