import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { animateScroll as scroll } from 'react-scroll'
import { useTranslation } from 'react-i18next';
import {
    CashIcon,
    ChartBarIcon,
    CodeIcon,
    CursorClickIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuIcon,
    RefreshIcon,
    ShieldCheckIcon,
    TrendingUpIcon,
    ViewGridIcon,
    XIcon,
    GlobeIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Link as LinkScroll } from 'react-scroll';
import LanguageSelect from '../LanguageSelect'

const solutions = [
    {
        name: 'Analytics',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: '#',
        icon: ChartBarIcon,
    },
    {
        name: 'Engagement',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '#',
        icon: CursorClickIcon,
    },
    { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
    {
        name: 'Integrations',
        description: "Connect with third-party tools that you're already using.",
        href: '#',
        icon: ViewGridIcon,
    },
    {
        name: 'Automations',
        description: 'Build strategic funnels that will drive your customers to convert',
        href: '#',
        icon: RefreshIcon,
    },
    {
        name: 'Reports',
        description: 'Get detailed reports that will help you make more informed decisions ',
        href: '#',
        icon: DocumentReportIcon,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FixedHeader() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    window.addEventListener('scroll', function () {
        var value = window.scrollY;
        if (window.location.href.endsWith('/')) {


            if (value > 90) {
                setShow(true);
            } else {
                setShow(false);
            }
        }


    });
    return (
        <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
        >

            <header className="fixed z-20 w-full shadow top-0">
                <Popover className="relative bg-white ">
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-4 sm:py-4  sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <div onClick={() => scroll.scrollToTop()} className="hover:cursor-pointer">
                                <span className="sr-only">P4L-GROUP</span>
                                <img
                                    className="h-12 w-auto sm:h-14"
                                    src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP2.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="flex items-center space-x-6 md:hidden">
                            <LanguageSelect />
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden md:flex md:space-x-3 lg:space-x-10 items-center">
                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? 'text-blue-700' : 'text-gray-500',
                                                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue-700 focus:outline-none  md:text-xs  lg:text-sm xl:text-base'
                                            )}
                                        >
                                            <span>{t('landing.header.group')}</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? 'text-blue-600' : 'text-gray-400',
                                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel className="absolute z-10 -ml-4 mt-7 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                    <div className="relative grid gap-6 bg-white px-4 py-5 sm:gap-8  lg:grid-cols-2">
                                                      
                                                        <LinkScroll activeClass="bg-blue-100" to="realEstate" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                                <HomeIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900"> {t('landing.header.P4LRealEstate')} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LRealEstateDescription')}</p>
                                                            </div>
                                                        </LinkScroll>
                                                        <LinkScroll activeClass="bg-blue-100" to="finance" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                                <CashIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{t('landing.header.P4LFinance')} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LFinanceDescription')}</p>
                                                            </div>
                                                        </LinkScroll>
                                                        <LinkScroll activeClass="bg-blue-100" to="lifeInSpain" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                                <GlobeIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{t('landing.header.LifeInSpain')} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.LifeInSpainDescription')}</p>
                                                            </div>
                                                        </LinkScroll>
                                                        <LinkScroll activeClass="bg-blue-100" to="investment" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                                <TrendingUpIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{t('landing.header.P4LInvestment')} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LInvestmentDescription')}</p>
                                                            </div>
                                                        </LinkScroll>
                                                        <LinkScroll activeClass="bg-blue-100" to="cst" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                                <CodeIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">  {t('landing.header.cleverSolutionsTechnology')} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.cleverSolutionsTechnologyDescription')}</p>
                                                            </div>
                                                        </LinkScroll>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                            {/*
                             <LinkScroll activeClass="text-blue-700" to="blog" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer md:text-xs lg:text-sm xl:text-base">
                                {t('landing.header.blog')}
                            </LinkScroll>
                            */}

                            <LinkScroll activeClass="text-blue-700" to="leadership" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer  md:text-xs lg:text-sm xl:text-base">
                                {t('landing.header.leadership')}
                            </LinkScroll>
                            <LinkScroll activeClass="text-blue-700" to="results" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer  md:text-xs lg:text-sm xl:text-base">
                                {t('landing.header.results')}
                            </LinkScroll>
                            {/*
                            <LinkScroll activeClass="text-blue-700" to="testimonials" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer md:text-xs lg:text-sm xl:text-base">
                                {t('landing.testimonials')}
                            </LinkScroll>
                        */}
                        </Popover.Group>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">

                            <LanguageSelect />

                            <Link
                                to="/contact"
                                className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-blue-600 hover:to-blue-900"
                            >
                                {t('landing.header.contact')}
                            </Link>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div onClick={() => scroll.scrollToTop()} className="hover:cursor-pointer">
                                            <img
                                                className="h-14 w-auto"
                                                src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP2.png"
                                                alt="P4L-GROUP Logo"
                                            />
                                        </div>
                                        <div className="flex items-center space-x-6 md:hidden  -mr-2">
                                            <LanguageSelect />
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <nav className="grid grid-cols-1 gap-7">
                                           
                                        <LinkScroll
                                            activeClass="bg-blue-100" to="realEstate" smooth={true} offset={-100}
                                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 group hover:cursor-pointer"
                                        >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white">
                                                <HomeIcon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                            <div className=" text-base font-medium text-gray-900 group-hover:text-blue-700 ">{t('landing.header.P4LRealEstate')}</div>
                                            <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LRealEstateDescription')}</p>
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass="bg-blue-100" to="finance" smooth={true} offset={-100}
                                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 group hover:cursor-pointer"
                                        >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white">
                                                <CashIcon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                            <div className="text-base font-medium text-gray-900 group-hover:text-blue-700 "> {t('landing.header.P4LFinance')}</div>
                                            <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LFinanceDescription')}</p>
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll activeClass="bg-blue-100" to="lifeInSpain" smooth={true} offset={-100} spy={true} className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50 hover:cursor-pointer">

                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white sm:h-12 sm:w-12">
                                                <GlobeIcon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-base font-medium text-gray-900">{t('landing.header.LifeInSpain')} </p>
                                                <p className="mt-1 text-sm text-gray-500">{t('landing.header.LifeInSpainDescription')}</p>
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass="bg-blue-100" to="investment" smooth={true} offset={-100}
                                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 group hover:cursor-pointer"
                                        >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white">
                                                <TrendingUpIcon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                            <div className="text-base font-medium text-gray-900 group-hover:text-blue-700 ">{t('landing.header.P4LInvestment')}</div>
                                            <p className="mt-1 text-sm text-gray-500">{t('landing.header.P4LInvestmentDescription')}</p>
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass="bg-blue-100" to="cst" smooth={true} offset={-100}
                                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 group hover:cursor-pointer"
                                        >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-blue-500 to-blue-800 text-white">
                                                <CodeIcon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                            <div className=" text-base font-medium text-gray-900 group-hover:text-blue-700 ">{t('landing.header.cleverSolutionsTechnology')}</div>
                                            <p className="mt-1 text-sm text-gray-500">{t('landing.header.cleverSolutionsTechnologyDescription')}</p>
                                            </div>
                                        </LinkScroll>
                                        </nav>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="grid grid-cols-6 gap-4">
                                        {/*
                                        <LinkScroll activeClass="text-blue-700" to="blog" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer md:text-xs lg:text-sm xl:text-base">
                                            {t('landing.header.blog')}
                                        </LinkScroll>
                                        */}
                                        <LinkScroll activeClass="text-blue-700" to="leadership" smooth={true} offset={-100} spy={true} className="text-base  text-center col-span-2 font-medium text-gray-900 hover:text-blue-700 hover:cursor-pointer">
                                            {t('landing.header.leadership')}
                                        </LinkScroll>
                                        <LinkScroll activeClass="text-blue-700" to="results" smooth={true} offset={-100} spy={true} className="text-base  text-center col-span-2 font-medium text-gray-900 hover:text-blue-700 hover:cursor-pointer">
                                            {t('landing.header.results')}
                                        </LinkScroll>
                                         {/*
                                        <LinkScroll activeClass="text-blue-700" to="testimonials" smooth={true} offset={-100} spy={true} className="text-base font-medium text-gray-500 hover:text-blue-700 hover:cursor-pointer md:text-xs lg:text-sm xl:text-base">
                                            {t('landing.testimonials')}
                                        </LinkScroll>
                                    */}
                                    </div>
                                    <div className="mt-6">
                                        <Link
                                            to="/contact"
                                            className="w-full flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-800 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-blue-600 hover:to-blue-900"
                                        >
                                            {t('landing.header.contact')}
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </header>
        </Transition>
    )
}