
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactPage from './components/contact';
import LandingPage from './components/landing';
import NotFoundPage from './components/notFound';
import { HelmetProvider  } from 'react-helmet-async';

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <HelmetProvider>
      <Routes>
        <Route exact path="/" element={<LandingPage/>} />
        <Route path="contact" element={<ContactPage/>} />
        <Route path="/404" element={<NotFoundPage/>} />
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
