import {
    SET_SUBMIT_CONTACT_MESSAGE,
    SET_MAIN_CONTACT_SUBMITTING,
    SET_SHOW_MODAL_LEGAL,
    SET_LANGUAGE
    } from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
    submitContactMessage: '',
    language: 'cz',
    showLegalModal: false,
    modalLegalType: 'private',
    submittingMainContactData: false,

};

const appUIReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        // Displays or closes modal and sets type of service displayed in modal
   
        case SET_MAIN_CONTACT_SUBMITTING:
            return {...state, submittingMainContactData: action.payload}
        case SET_SHOW_MODAL_LEGAL:
            const newLegalModalServiceType =  action.payload.legalType === '' ? state.modalLegalType : action.payload.legalType;
            return {...state, showLegalModal: action.payload.show, modalLegalType: newLegalModalServiceType}
    
        case SET_SUBMIT_CONTACT_MESSAGE:
            return {...state, submitContactMessage: action.payload}
        case SET_LANGUAGE:
            return {...state, language: action.payload}
            
        default:
            return state;
    }
};
export default appUIReducer;