import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// redux imports
import { Provider, } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'; // applyMiddleware for reduxThunk, compose for redux devtools
import reduxThunk from 'redux-thunk'; // Used for asynchronous action creators
import reducers from './reducers';
//Scroll animation styles
import "animate.css/animate.min.css";
// Language translation
import './i18n';

// setup devtools for debugging redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// create redux store from combined reducers
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
