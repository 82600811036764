import {
    AcademicCapIcon,
    FingerPrintIcon,
    ChartBarIcon,
    ShieldCheckIcon,
  } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
  
 
  
  export default function OurValues() {
    const {t} = useTranslation();

    const featuresSM = [
      {
        name: t('landing.values.trust'),
        description: t('landing.values.trustDes'),
        icon: ShieldCheckIcon,
      },
      {
        name: t('landing.values.personalAproach'),
        description: t('landing.values.personalAproachDes'),
        icon: FingerPrintIcon,
      },
      {
        name: t('landing.values.quality'),
        description: t('landing.values.qualityDes'),
        icon: AcademicCapIcon,
      },
      {
        name: t('landing.values.innovation'),
        description: t('landing.values.innovationDes'),
        icon: ChartBarIcon,
      },
   
    ]
    const features = [
      {
        name: t('landing.values.trust'),
        description: t('landing.values.trustDes'),
        icon: ShieldCheckIcon,
      },
      {
        name: t('landing.values.personalAproach'),
        description: t('landing.values.personalAproachDesBigRes'),
        icon: FingerPrintIcon,
      },
      {
        name: t('landing.values.quality'),
        description: t('landing.values.qualityDes'),
        icon: AcademicCapIcon,
      },
      {
        name: t('landing.values.innovation'),
        description: t('landing.values.innovationDesBigRes'),
        icon: ChartBarIcon,
      },
   
    ]

    return (
      <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="results">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
         
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {t('landing.header.results')}
          </p>
          <h2 className="text-base font-semibold uppercase tracking-wider text-blue-600 mt-4">     {t('landing.results.title')}</h2>
          <div className="mt-12">
            <div className="lg:hidden grid grid-cols-1 gap-8 sm:grid-cols-2 ">
              
              {featuresSM.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-blue-500 to-blue-800 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500" dangerouslySetInnerHTML={{__html: feature.description}}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="hidden lg:grid grid-cols-1 gap-8 sm:grid-cols-2 ">
              
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-blue-500 to-blue-800 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500" dangerouslySetInnerHTML={{__html: feature.description}}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }