
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import LanguageSelect from '../LanguageSelect'
import { useTranslation } from 'react-i18next';
const navigation = [
    { name: 'Changelog', href: '#' },
    { name: 'About', href: '#' },
    { name: 'Partners', href: '#' },
    { name: 'News', href: '#' },
]

export default function ContactHeader() {
    const { t } = useTranslation();
    return (
        <>
            <header className="relative">
                <div className="bg-warm-gray-50">
                    <nav
                        className="relative max-w-7xl mx-auto flex items-center justify-between pt-6 px-6 xl:px-8"
                        aria-label="Global"
                    >
                        <div className="flex items-center flex-1">
                            <div className="flex items-center justify-between w-full lg:w-auto">
                                <Link to="/">
                                    <span className="sr-only">P4L-Group</span>
                                    <img
                                        className="h-12 w-auto sm:h-16"
                                        src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/site/logos/PartnerLogoP4LGROUP2.png"
                                        alt=""
                                    />
                                </Link>
                            </div>

                        </div>
                        <div className="flex items-center space-x-6">
                            <LanguageSelect />
                            <Link to="/"
                                className="py-2 px-6 bg-blue-100 border border-transparent rounded-md text-base font-medium text-blue-700 hover:bg-blue-200"
                            >
                                 {t('common.home')}
                            </Link>
                        </div>
                    </nav>
                </div>


            </header>
        </>
    )
}